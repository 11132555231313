import { Box, Button, useTheme, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { Device, Sensor } from "../../../../Interface";

interface Props {
  device: Sensor;
  updateDevice: (device: Device) => void;
}

export const SensorControl: FunctionComponent<Props> = (props) => {
  const { device, updateDevice } = props;
  const theme = useTheme();
  const sensorValue = props.device.sensor.sensorValue;
  const sensorUdm = props.device.sensor.sensorUdm;
  const sensorTxtConvert = props.device.sensor.sensorTxtConvert;

  return (
    <Box display='flex' justifyContent='center'>
      {device.sensor.sensorDisconnect !== undefined && (
        <Box
          width='150px'
          borderRadius='10px'
          padding='10px'
          display='flex'
          flexDirection='column'
          bgcolor={theme.palette.background.default}
        >
          <Button
            style={{ flexGrow: 1, minHeight: "100px", marginBottom: "10px" }}
            onClick={() => {
              let newDev = JSON.parse(JSON.stringify(device));
              newDev.sensor.sensorDisconnect = false;
              updateDevice(newDev);
            }}
            color='primary'
          >
            <i className='fas fa-bell fa-2x' />
          </Button>
          <Button
            style={{ flexGrow: 1, minHeight: "100px" }}
            onClick={() => {
              let newDev = JSON.parse(JSON.stringify(device));
              newDev.sensor.sensorDisconnect = true;
              updateDevice(newDev);
            }}
            color='primary'
          >
            <i className='fas fa-bell-slash fa-2x' />
          </Button>
        </Box>
      )}
      {sensorValue !== undefined &&
        (sensorTxtConvert ? (
          <Box>
            <Typography variant='h4' gutterBottom>
              {sensorTxtConvert.find((e) => e.val === sensorValue)?.txt || ""}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant='h4' gutterBottom>
              {sensorValue} {sensorUdm !== undefined && sensorUdm}
            </Typography>
          </Box>
        ))}
    </Box>
  );
};
