import { Fab, ListItemIcon, Menu, MenuItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import arrayMove from "array-move";
import Axios from "axios";
import * as React from "react";
import { Context, IContext } from "../../Context";
import { TitleBar } from "../Layout/TitleBar";
import {
  DeviceWidget,
  ScenarioWidget,
  Widget,
  widgetIcons,
} from "./Widgets/Widgets";
import { WidgetWrapper } from "./Widgets/WidgetWrapper";
import { SortableElement, SortableContainer } from "react-sortable-hoc";

// Props del componente
interface HomeProps {
  widgets: Widget[];
  setWidgets: (newWidgets: Widget[]) => Promise<unknown>;
}

interface HomeState {
  widgets: Widget[];
  showPopup: boolean;
  widgetsNames: string[];
  anchorEl: null | HTMLElement;
}

// Componente che renderizza i WidgetHome (box contenenti nomi delle pagine, icone) sulla pagina home.
// Se nel file di configurazione per la pagina è specicificato che, per esempio, la pagina dei dispositivi deve essere
// mostrata, allora mostrerà il box, altrimenti no.
export class Home extends React.Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props);

    this.state = {
      widgets: this.props.widgets,
      showPopup: false,
      widgetsNames: [
        "Clock",
        "Lights Counter",
        "Scenario",
        "Blind Counter",
        // 'Thermostat',
        "Stop Blinds",
        "Thermostat List",
        "Device",
      ],
      anchorEl: null,
    };

    this.onSortEnd = this.onSortEnd.bind(this);
    this.updateWidgets = this.updateWidgets.bind(this);
    this.setDevice = this.setDevice.bind(this);
    this.deleteWidget = this.deleteWidget.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidUpdate(oldProps: HomeProps) {
    if (
      JSON.stringify(oldProps.widgets) !== JSON.stringify(this.props.widgets)
    ) {
      this.setState({
        widgets: this.props.widgets,
      });
    }
  }

  private SortableItemWidgetPanel = SortableElement(
    ({ value, position }: { value: Widget; position: number }) => (
      <WidgetWrapper
        deleteWidget={this.deleteWidget}
        setDevice={this.setDevice}
        index={position}
        value={value}
      />
    )
  );

  private SortableList = SortableContainer(({ items }: { items: Widget[] }) => {
    const SortableItemWidgetPanel = this.SortableItemWidgetPanel
    return (
      <div className="home-list">
        {items.map((widget, index) => (
          <SortableItemWidgetPanel
            key={widget.id}
            position={index}
            index={index}
            value={widget}
          />
        ))}
      </div>
    );
  });

  private onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    this.setState(
      {
        widgets: arrayMove(this.state.widgets, oldIndex, newIndex),
      },
      this.updateWidgets
    );
  };

  private deleteWidget(index: number) {
    const newList = [...this.state.widgets];
    newList.splice(index, 1);
    this.setState(
      {
        widgets: newList,
      },
      this.updateWidgets
    );
  }

  private setDevice(index: number, id: number) {
    let newWidgets = this.state.widgets;
    if (this.state.widgets[index].type === "scenario") {
      (newWidgets[index] as ScenarioWidget).scenario = id;
    } else if (this.state.widgets[index].type === "device") {
      (newWidgets[index] as DeviceWidget).device = id;
    } else {
      console.log("Type of device incorrect");
      return;
    }
    this.setState(
      {
        widgets: newWidgets,
      },
      this.updateWidgets
    );
  }

  private async updateWidgets() {
    Axios.post("apiwidget", this.state.widgets)
      .then((res) => {
        // console.log(res.status + " " + res.statusText);
        // this.props.setWidgets(this.state.widgets);
        if (res.data.error) {
          console.log("Error: " + res.status + " " + res.data.error);
        }
      })
      .catch((err) => {
        console.log("Widgets updated.");
        console.log(err);
      });
  }

  private addWidget(widgetName: string) {
    let newWidget: Widget | undefined;
    let id = Math.max(...this.state.widgets.map((widget) => widget.id), -1) + 1;
    switch (widgetName) {
      case "Clock":
        newWidget = { id, type: "clock" };
        break;
      case "Lights Counter":
        newWidget = { id, type: "lightsCounter" };
        break;
      case "Scenario":
        newWidget = { id, type: "scenario" };
        break;
      case "Blind Counter":
        newWidget = { id, type: "blindsCounter" };
        break;
      // case 'Thermostat':
      //     newWidget = { type: 'thermo' };
      //     break;
      case "Stop Blinds":
        newWidget = { id, type: "stopBlinds" };
        break;
      case "Thermostat List":
        newWidget = { id, type: "thermoList" };
        break;
      case "Device":
        newWidget = { id, type: "device" };
        break;
      default:
        newWidget = undefined;
    }
    if (newWidget) {
      this.setState(
        {
          widgets: [...this.state.widgets, newWidget],
        },
        this.updateWidgets
      );
    }
  }

  private handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ anchorEl: event.currentTarget });
  }

  private handleClose() {
    this.setState({ anchorEl: null });
  }
  public render() {
    const context: IContext = this.context;
    return (
      <>
        <TitleBar title="Home" />
        {/* \\ localization */}
        <this.SortableList
          onSortEnd={this.onSortEnd}
          items={this.state.widgets}
          helperClass={"scenario-dragged"}
          pressDelay={500}
          axis="xy"
        />

        <Fab onClick={this.handleClick} className="fab-fixed" color="primary">
          <AddIcon />
        </Fab>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          {this.state.widgetsNames.map((widget: string, index) => (
            <MenuItem
              key={"dropdown-" + widget}
              onClick={() => {
                this.addWidget(widget);
                this.handleClose();
              }}
            >
              <ListItemIcon>
                <div className="color-accent ml-2">
                  <i className={(widgetIcons as any)[widget]} />
                </div>
              </ListItemIcon>
              {context.i18n[context.language].widgets.menu[widget]}
            </MenuItem>
          ))}
        </Menu>
        {/* <Dropdown
                    drop="up"
                    title="Add a new widget"
                    id="dropdown-add-widget"
                    key="up"
                    className="fab-fixed"
                >
                    <Dropdown.Toggle
                        id="dropdown-custom-1"
                        // className="new-fab-btn my-bg-accent color-white"
                        split={false}
                        // @ts-ignore
                        as={myFab}
                    />
                    <Dropdown.Menu className="widget-menu">
                        {this.state.widgetsNames.map(
                            (widget: string, index) => (
                                <Dropdown.Item
                                    key={'dropdown-' + widget}
                                    eventKey={index + ''}
                                    onClick={() => this.addWidget(widget)}
                                    className="widget-menu-item"
                                >
                                    {
                                        context.i18n[context.language].widgets
                                            .menu[widget]
                                    }
                                    <div className="widget-menu-item-icon my-bg-accent">
                                        <i
                                            className={
                                                (widgetIcons as any)[widget]
                                            }
                                        />
                                    </div>
                                </Dropdown.Item>
                            ),
                        )}
                    </Dropdown.Menu>
                </Dropdown> */}
      </>
    );
  }
}
Home.contextType = Context;
