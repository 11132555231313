import {
  Badge,
  Box,
  ButtonBase,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { Context } from "../../Context";
import {
  Blind,
  blindMoveEnum,
  Device,
  DeviceType,
  DimLight,
  QualityEnum,
} from "../../Interface";
import { Sensor, Thermostat } from "./../../Interface";
import { MapContext } from "./MapContext";
import { DeviceModal } from "./Modals/DeviceModal";
import { RenderDeviceIcon } from "./RenderDeviceIcon";

interface Props {
  device: Device;
}

export const RenderDevice: FunctionComponent<Props> = (props) => {
  const { device } = props;
  const context = useContext(Context);
  const { scenario, setSwipe } = useContext(MapContext);

  const theme = useTheme();
  const bgColor = theme.palette.background.paper;
  const custom = context.custom;
  const name = custom[context.language].devices.name[device.id];
  const [open, setOpen] = useState(false);

  const [isScenario, setIsScenario] = useState(false);

  useEffect(() => {
    setIsScenario(scenario?.devices.some((d) => d.id === device.id) || false);
  }, [scenario]);


  const closeModal = () => setOpen(false);
  const handleClick = () => setOpen(true);

  return (
    <>
      <Badge
        color='error'
        overlap='circle'
        variant='dot'
        invisible={
          device.quality === QualityEnum.good ||
          device.type === DeviceType.scene
        }
      >
        <ButtonBase
          style={{ borderRadius: "10px" }}
          component='div'
          onClick={handleClick}
        >
          <Box
            className='devices-card'
            bgcolor={bgColor}
            color={theme.palette.getContrastText(bgColor)}
            border={isScenario ? 3 : undefined}
            style={{ borderColor: theme.palette.primary.main }}
            boxShadow={theme.shadows[1]}
          >
            <Box display='flex' flexDirection='column'>
              <RenderDeviceIcon device={device} className='fa-2x m-1' />
              <Typography variant='overline' noWrap>
                <Box marginLeft={0.5}>{renderDeviceExtra(device)}</Box>
              </Typography>
            </Box>
            <Typography variant='caption'>
              <LinesEllipsis
                style={{ whiteSpace: "pre-wrap" }}
                maxLine='2'
                text={name}
                trimRight={false}
                basedOn='words'
              />
            </Typography>
          </Box>
        </ButtonBase>
      </Badge>
      <DeviceModal open={open} onClose={closeModal} device={device} />
    </>
  );
};

const renderDeviceExtra = (device: Device) => {
  if ((device as Thermostat).thermostat) {
    const thermostat = (device as Thermostat).thermostat;
    return thermostat.temperature + "°";
  } else if ((device as Sensor).sensor?.sensorValue !== undefined) {
    const sensor = (device as Sensor).sensor;
    if (sensor.sensorTxtConvert) {
      return sensor.sensorTxtConvert.find((e) => e.val === sensor.sensorValue)
        ?.txt;
    } else {
      return `${sensor.sensorValue} ${
        sensor.sensorUdm !== undefined && sensor.sensorUdm
      }`;
    }
  } else if ((device as DimLight).light?.dim) {
    const light = (device as DimLight).light;
    return light.dim.value + "%";
  } else if ((device as Blind).blind) {
    const blind = (device as Blind).blind;
    if (blind.move === blindMoveEnum.UP || blind.move === blindMoveEnum.DOWN) {
      return (
        <Box>
          {blind.move === blindMoveEnum.UP ? (
            <i className='fas fa-chevron-circle-up' />
          ) : (
            <i className='fas fa-chevron-circle-down' />
          )}
        </Box>
      );
    }
  }
};
