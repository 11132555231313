import { Checkbox, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import * as React from "react";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import { Context, ContextConsumer, IContext } from "../../Context";
import { ScenarioInterface } from "../../Interface";
import history from "../history";
import { TitleBar } from "../Layout/TitleBar";
import { StarBorder, Star } from "@material-ui/icons";

// Props del componente
interface ScenarioHomeProps {
  scenarios: Array<ScenarioInterface>; // Lista di scenari
  newPage?: ScenarioInterface; // Scenario vuoto utilizzato nel caso di creazione di uno scenario
  editForm: Function;
  handleExecute: Function;
}

interface ScenarioHomeState {
  scenarios: Array<ScenarioInterface>;
  favourite: boolean;
}

// Componente che si occupa di renderizzare lo scenario selezionato. Può mostrare lo scenario in due modalità:
// modalità mostra (con il componente ScenarioHomeShow) o modalità modifica (con il componente ScenarioHomeForm).
export class ScenarioHome extends React.Component<
  ScenarioHomeProps,
  ScenarioHomeState
> {
  constructor(props: ScenarioHomeProps) {
    super(props);

    this.state = {
      scenarios: this.props.scenarios,
      favourite: false,
    };

    this.onSortEnd = this.onSortEnd.bind(this);
    this.toggleFavourite = this.toggleFavourite.bind(this);
  }

  public componentDidUpdate(prevProps: ScenarioHomeProps) {
    if (
      JSON.stringify(this.props.scenarios) !==
      JSON.stringify(prevProps.scenarios)
    ) {
      this.setState({
        scenarios: this.props.scenarios,
      });
    }
  }

  private SortableItem = SortableElement(
    ({ value }: { value: ScenarioInterface }) => {
      const context: IContext = this.context;
      const name =
        context.custom[context.language].scenarios.name[value.id] || value.name;
      const description =
        context.custom[context.language].scenarios.description[value.id] ||
        value.description;

      return (
        <ContextConsumer>
          {(context: IContext) => (
            <div className="scenario-card my-bg-secondary">
              <div
                onClick={() => history.push(`/scenarios/${value.id}`)}
                className="scenario-card-info"
              >
                <div>{name}</div>
                <div className="scenario-card-subtitle">
                  {description !== "" && description.substring(0, 40)}
                </div>
              </div>
              <div className="scenario-card-icons">
                <div
                  onClick={() => this.props.handleExecute(value.id)}
                  style={{
                    cursor: "pointer",
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "-10px",
                  }}
                >
                  <i className="fas fa-play-circle fa-2x color-accent" />
                </div>
                <div
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {value.favourite && (
                    <i className="fas fa-star color-accent" />
                  )}
                </div>
                <div
                  style={{
                    opacity: 0.2,
                    flexGrow: 1,
                    flexBasis: 12,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {value.readOnly && <i className={"fas fa-lock"} />}
                </div>
              </div>
            </div>
          )}
        </ContextConsumer>
      );
    }
  );

  private SortableList = SortableContainer(
    ({ items }: { items: ScenarioInterface[] }) => {
      const SortableItem = this.SortableItem;
      return (
        <div className="scenario-list">
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ))}
        </div>
      );
    }
  );

  private onSortEnd = async ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    this.setState(
      {
        scenarios: arrayMove(this.state.scenarios, oldIndex, newIndex),
      },
      async () => {
        axios
          .patch("apiscenarios", {
            old: oldIndex,
            new: newIndex,
          })
          .then((res) => {
            if (res) {
              console.log(res.status + " " + res.statusText);

              // TODO gestire errori
              if (res.data.error) {
                console.log("RESPONSE ERROR");
              } else {
                console.log("Scenario list order saved");
              }
            }
          })
          .catch((err) => {
            // TODO
            // Se c'è un errore, aggiungi a lista di errori
            // e mostra una notifica di connessione
          });
      }
    );
  };

  private toggleFavourite() {
    this.setState({
      favourite: !this.state.favourite,
    });
  }

  public render() {
    return (
      <ContextConsumer>
        {(context: IContext) => (
          <div className="scenario-home">
            <TitleBar
              title={
                <>
                  {context.i18n[context.language].scenarios.title}
                  <Checkbox
                    onChange={() => this.toggleFavourite()}
                    checked={this.state.favourite}
                    icon={<StarBorder />}
                    color="primary"
                    checkedIcon={<Star />}
                  />
                </>
              }
            />
            <this.SortableList
              onSortEnd={this.onSortEnd}
              items={this.state.scenarios.filter(
                (scenario) => !this.state.favourite || scenario.favourite
              )}
              helperClass={"scenario-dragged"}
              pressDelay={500}
              axis="xy"
            />
            {/* {this.state.scenarios
              .filter((scenario) => !this.state.favourite || scenario.favourite)
              .map((scenario, index) => {
                return (
                  <this.SortableItem
                    value={scenario}
                    key={`scenario-${index}`}
                  />
                );
              })} */}
            <Fab
              onClick={() => {
                context.setLock(true);
                this.props.editForm(true);
                history.push("/scenarios/new");
              }}
              color="primary"
              className="fab-fixed"
            >
              <AddIcon />
            </Fab>
          </div>
        )}
      </ContextConsumer>
    );
  }
}

ScenarioHome.contextType = Context;
