import FullCalendar from '@fullcalendar/react';
import * as React from 'react';
import { Event } from "./Interface";

export interface CalendarContext {
  calendar: React.RefObject<FullCalendar> | null;
  event?: Event;
  dialog: boolean;
  setEvent: (event: Event) => void;
  setDialog: (open: boolean) => void;
}

const context = React.createContext<CalendarContext>({
  calendar: null,
  dialog: false,
  setDialog: (open) => {},
  setEvent: (event) => {},
});

export const CalendarContextProvider = context.Provider;
export const CalendarContextConsumer = context.Consumer;
export const CalendarContext = context;
