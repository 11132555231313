import * as React from "react";
import { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    Button,
    Typography,
    useTheme,
} from "@material-ui/core";
import Axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

interface Props {
    open: boolean;
    onClose: () => void;
}

export const ChangePasswordModal: React.SFC<Props> = (props) => {
    const { open, onClose } = props;
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [showError, setShowError] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const theme = useTheme();
    React.useEffect(() => {
        if (open) {
            setOldPassword("");
            setNewPassword("");
            setRepeatPassword("");
            setShowError("");
        }
    }, [open]);
    const submit = async () => {
        if (newPassword.length < 1) {
            setShowError("Password must be at least 1 character");
            return;
        }
        if (newPassword !== repeatPassword) {
            setShowError("Passwords must match");
            return;
        }
        Axios.post("/OmniVision/ChangePassword", [oldPassword, newPassword])
            .then((res) => {
                if (res.status === 200) {
                    onClose();
                    MySwal.fire({
                        icon: "success",
                        title: "Password changed",
                        background: theme.palette.background.paper,
                    });
                }
            })
            .catch((_err) => setShowError("Wrong Password"));
    };
    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <TextField
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        label="Old password"
                        fullWidth
                        type="password"
                        margin="normal"
                    />
                    <TextField
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        label="New password"
                        fullWidth
                        type="password"
                        margin="normal"
                    />
                    <TextField
                        error={repeatPassword !== "" && newPassword !== repeatPassword}
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        label="Repeat new password"
                        fullWidth
                        type="password"
                        margin="normal"
                    />
                    <Typography color="error">{showError}</Typography>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    variant="contained"
                    disabled={repeatPassword === "" || repeatPassword !== newPassword}
                    color="primary"
                    onClick={submit}
                >
                    Confirm
        </Button>
            </DialogActions>
        </Dialog>
    );
};
