import * as React from 'react';
import { Context, IContext } from '../../Context';
import variable from '../../styles/variable.scss';

// Props del componente
interface StyleProps {
}

// Componente che renderizza la pagina delle preferenze.
export const Style: React.SFC<StyleProps> = (props) => {
    const context: IContext = React.useContext(Context);
    function setTheme(event: React.ChangeEvent<HTMLInputElement>) {
        localStorage.setItem('theme', event.target.value);
        document.documentElement.style.setProperty(
            '--primary',
            variable[`${event.target.value}-primary`],
        );
        document.documentElement.style.setProperty(
            '--secondary',
            variable[`${event.target.value}-secondary`],
        );
        document.documentElement.style.setProperty(
            '--text-color',
            event.target.value === 'light' ? '#000000' : '#ffffff',
        );
        context.setTheme(event.target.value);
    }

    function setAccent(event: React.ChangeEvent<HTMLInputElement>) {
        localStorage.setItem('accent', event.target.value);
        document.documentElement.style.setProperty(
            '--accent',
            variable[event.target.value],
        );
        context.setAccent(event.target.value);
    }
    let color = '';
    switch (context.accent) {
        case 'green':
            color = 'p-success-o';
            break;
        case 'blue':
            color = 'p-primary-o';
            break;
        case 'red':
            color = 'p-danger-o';
            break;
    }
    return (
        <React.Fragment>
            <div className="my-bg-secondary settings-option">
                <div className="settings-option-title">
                    {context.i18n[context.language].settings.style.theme}
                </div>
                <form action="">
                    <div className="pretty p-default p-round">
                        <input
                            type="radio"
                            name="theme"
                            value="light"
                            checked={context.theme === 'light'}
                            onChange={(event) => setTheme(event)}
                        />
                        <div className={`state ${color}`}>
                            <label>
                                {
                                    context.i18n[context.language].settings
                                        .style.light
                                }
                            </label>
                        </div>
                    </div>
                    <br />
                    <div className="pretty p-default p-round">
                        <input
                            type="radio"
                            name="theme"
                            value="dark"
                            checked={context.theme === 'dark'}
                            onChange={(event) => setTheme(event)}
                        />
                        <div className={`state ${color}`}>
                            <label>
                                {
                                    context.i18n[context.language].settings
                                        .style.dark
                                }
                            </label>
                        </div>
                    </div>
                    <br />
                </form>
            </div>
            <div className="my-bg-secondary settings-option">
                <div className="settings-option-title">
                    {context.i18n[context.language].settings.style.accent}
                </div>
                <form action="">
                    <div className="pretty p-default p-round">
                        <input
                            type="radio"
                            name="accent"
                            value="green"
                            checked={context.accent === 'green'}
                            onChange={(event) => setAccent(event)}
                        />
                        <div className={`state ${color}`}>
                            <label>
                                {
                                    context.i18n[context.language].settings
                                        .style.green
                                }
                            </label>
                        </div>
                    </div>
                    <br />
                    <div className="pretty p-default p-round">
                        <input
                            type="radio"
                            name="accent"
                            value="red"
                            checked={context.accent === 'red'}
                            onChange={(event) => setAccent(event)}
                        />
                        <div className={`state ${color}`}>
                            {' '}
                            <label>
                                {
                                    context.i18n[context.language].settings
                                        .style.red
                                }
                            </label>
                        </div>
                    </div>
                    <br />
                    <div className="pretty p-default p-round">
                        <input
                            type="radio"
                            name="accent"
                            value="blue"
                            checked={context.accent === 'blue'}
                            onChange={(event) => setAccent(event)}
                        />
                        <div className={`state ${color}`}>
                            <label>
                                {
                                    context.i18n[context.language].settings
                                        .style.blue
                                }
                            </label>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};
