import * as React from "react";

interface DeviceIcon {
  default: string;
  className?: string;
  style?: React.CSSProperties;
  status: number;
  icons?: number[];
}

export const DeviceIcon: React.FunctionComponent<DeviceIcon> = (props) => {
  let OnIcon = null;
  let OffIcon = null;
  const icons_file = require("../../JsonDefaults/Icons.json");
  if (props.icons) {
    OnIcon = icons_file.icons[props.icons[1]];
    OffIcon = icons_file.icons[props.icons[0]];
  }
  const className = props.className || "";
  const style = props.style || {};
  return props.icons && props.icons.length > 0 ? (
    props.icons.length > 1 ? (
      props.status ? (
        <i className={"icon-" + OnIcon + " " + className} style={style} />
      ) : (
        <i className={"icon-" + OffIcon + " " + className} style={style} />
      )
    ) : (
      <i className={"icon-" + OffIcon + " " + className} style={style} />
    )
  ) : (
    <i className={props.default + " " + className} style={style} />
  );
};
