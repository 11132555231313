import axios from "axios";
import * as React from "react";
import { Redirect } from "react-router";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Context, IContext } from "../../Context";
import { Custom, Device, ScenarioInterface, SubLevel } from "../../Interface";
import variable from "../../styles/variable.scss";
import history from "../history";
import { ScenarioForm } from "./ScenarioForm";
import { ScenarioHome } from "./ScenarioHome";
import { ScenarioShow } from "./ScenarioShow";

const MySwal = withReactContent(Swal);

// Props del componente
interface ScenarioContainerProps {
  scenarios: Array<ScenarioInterface>; // Lista di scenari
  toDevices: Function; // Funzione utilizzata per rimandare alla pagina dei dispositivi
  allDevices: Device[]; // Lista di dispositivi
  allLevels: SubLevel[]; // Lista dei livelli
  // editForm: Function; // used to set the form parameter in Display
  match: any;
}

// State del componente
interface ScenarioContainerState {
  scenario?: ScenarioInterface; // Pagina (scenario) in cui si trova l'utente
  scenarios: Array<ScenarioInterface>; // Lista di scenari
  edit: boolean;
}

// Componente che renderizza la pagina degli scenari. Si occupa di renderizzare il menu laterale con la lista di scenari e la pagina con lo scenario selezionato (o la pagina di creazione di uno scenario).
export class ScenarioContainer extends React.Component<
  ScenarioContainerProps,
  ScenarioContainerState
> {
  public componentDidMount() {
    this.setState({
      edit: !!this.context.scenario,
    });
  }

  constructor(props: ScenarioContainerProps) {
    super(props);
    this.state = {
      scenarios: this.props.scenarios,
      edit: false,
    };

    // this.selectScenario = this.selectScenario.bind(this);
    this.handleIsModify = this.handleIsModify.bind(this);
    this.updateScenarios = this.updateScenarios.bind(this);
    this.updateScenariosDelete = this.updateScenariosDelete.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleExecute = this.handleExecute.bind(this);
    this.modifyScenario = this.modifyScenario.bind(this);
    this.addNewScenario = this.addNewScenario.bind(this);
    this.printPath = this.printPath.bind(this);
    this.generateId = this.generateId.bind(this);
    this.newScenario = this.newScenario.bind(this);
    this.editForm = this.editForm.bind(this);
  }

  // Funzione che si occupa di chiudere la modalità form quando si visualizza uno scenario.
  public handleCancel(setLock: Function) {
    this.setState(
      {
        edit: false,
      },
      () => setLock(false)
    );
  }

  public editForm(val: boolean) {
    this.setState({
      edit: val,
    });
  }

  public async addNewScenario(scenario: ScenarioInterface) {
    const context: IContext = this.context;
    const toSend: Custom = JSON.parse(JSON.stringify(context.custom));
    if (toSend.de) {
      toSend.de.scenarios.name[scenario.id] = scenario.name;
      toSend.de.scenarios.description[scenario.id] = scenario.description;
    }
    if (toSend.en) {
      toSend.en.scenarios.name[scenario.id] = scenario.name;
      toSend.en.scenarios.description[scenario.id] = scenario.description;
    }
    if (toSend.it) {
      toSend.it.scenarios.name[scenario.id] = scenario.name;
      toSend.it.scenarios.description[scenario.id] = scenario.description;
    }

    axios.post("apicustom", toSend);
    axios
      .post("apiscenarios", scenario)
      .then((res) => {
        if (res) {
          console.log(res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.setState({
      edit: false,
    });
    context.setLock(false).then(() => {
      history.push("/scenarios");
    });
  }

  // Funzione che si occupa di mandare i dati al backend per aggiornare la lista di scenari.
  public async modifyScenario(scenario: ScenarioInterface) {
    const context = this.context;
    const toSend = {
      ...context.custom,
      [context.language]: {
        ...context.custom[context.language],
        scenarios: {
          name: {
            ...context.custom[context.language].scenarios?.name,
            [scenario.id]: scenario.name,
          },
          description: {
            ...context.custom[context.language].scenarios?.description,
            [scenario.id]: scenario.description,
          },
        },
      },
    };

    axios.post("apicustom", toSend);
    axios
      .put("apiscenarios", scenario)
      .then((res) => {
        if (res) {
          console.log(res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.setState({
      edit: false,
    });
    context.setLock(false);
  }

  // Funzione che si occupa di cancellare uno scenario.
  public async handleDelete(scenario: ScenarioInterface) {
    const context: IContext = this.context;

    MySwal.fire({
      title: context.i18n[context.language].swal.deleteScenario,
      text: context.i18n[context.language].swal.areYouSure,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      customClass: `my-bg-secondary`,
      confirmButtonColor: variable[context.accent],
    }).then((result) => {
      if (result.value) {
        MySwal.fire({
          title: context.i18n[context.language].swal.scenarioDeleted,
          icon: "success",
          timer: 1500,
          customClass: `my-bg-secondary`,
          confirmButtonColor: variable[context.accent],
        });
        history.replace("/scenarios");
        var url: string = "apiscenarios/" + scenario.id;
        axios.delete(url).finally(() => {
          this.updateScenariosDelete(scenario);
        });
      }
    });
  }

  // Funzione che si occupa di eseguire lo scenario attivo.
  public async handleExecute(id: number, newScenario?: boolean): Promise<void> {
    const context: IContext = this.context;
    return new Promise((resolve, rejects) => {
      let activeScenario = this.props.scenarios.find((a) => a.id === id);
      console.log(activeScenario);
      if (activeScenario) {
        if (activeScenario.devices.length !== 0) {
          MySwal.fire({
            title: context.i18n[context.language].swal.scenarioExecution,
            text: context.i18n[context.language].swal.areYouSure,
            icon: "question",
            showCancelButton: true,
            reverseButtons: true,
            customClass: `my-bg-secondary`,
            confirmButtonColor: variable[context.accent],
          }).then((result) => {
            if (result.value) {
              MySwal.fire({
                title: "Scenario executed",
                icon: "success",
                timer: 1500,
                customClass: `my-bg-secondary`,
                confirmButtonColor: variable[context.accent],
              });
              if (activeScenario) {
                axios
                  .head("apiscenarios/" + activeScenario.id)
                  .then((res) => {
                    if (res) {
                      console.log(res.status + " " + res.statusText);
                      console.log("Scenario executed.");
                    }
                    resolve();
                  })
                  .catch((err) => {
                    if (err) {
                      console.log("Scenario not executed.");
                    }
                    rejects();
                  });
              }
            }
          });
        } else {
          resolve();
        }
      } else if (newScenario) {
        resolve();
      }
    });
  }

  // Quando si clicca sul bottone modifica nella pagina dello scenario, questa funzione viene chiamata
  // e cambia la proprietà editMode in modo da mostrare il form.
  public handleIsModify(setLock: Function) {
    this.setState({
      edit: true,
    });
    setLock(true);
  }

  //TOFIX this should not be necessary as the updated list will arrive as props and the component will re-render
  // Dopo aver creato/modificato uno scenario, aggiorna la lista di scenari attuale in modo da avere lo scenario modificato.
  public updateScenarios(scenario: ScenarioInterface) {
    var updateScenario = this.state.scenarios.find((x) => x.id === scenario.id);
    var scenarios = this.state.scenarios;
    if (updateScenario) {
      var index = this.state.scenarios.indexOf(updateScenario);
      scenarios[index] = scenario;
    } else {
      scenarios.push(scenario);
    }
    this.setState({
      scenarios: scenarios,
    });
  }

  private setScenarioFromCustom(
    custom: Custom,
    id: number,
    value: string
  ): Custom {
    const newCustom: Custom = JSON.parse(JSON.stringify(custom));
    if (newCustom.de && newCustom.de.scenarios) {
      newCustom.de.scenarios.description[id] = value;
      newCustom.de.scenarios.name[id] = value;
    }
    if (newCustom.it && newCustom.it.scenarios) {
      newCustom.it.scenarios.description[id] = value;
      newCustom.it.scenarios.name[id] = value;
    }
    if (newCustom.en && newCustom.en.scenarios) {
      newCustom.en.scenarios.description[id] = value;
      newCustom.en.scenarios.name[id] = value;
    }
    return newCustom;
  }

  //TODO aggiornare Layout in modo che chieda la nuova lista al backend
  // Dopo che uno scenario è stato eliminato, aggiorna la lista di scenari attuale in modo da non avere piu lo scenario cancellato.
  public updateScenariosDelete(scenario: ScenarioInterface) {
    var updateScenario = this.state.scenarios.find((x) => x.id === scenario.id);
    var scenarios = this.state.scenarios;
    if (updateScenario) {
      var index = this.state.scenarios.indexOf(updateScenario);
      scenarios.splice(index, 1);
    }
    const context: IContext = this.context;
    const custom = this.setScenarioFromCustom(
      context.custom,
      scenario.id,
      undefined as any
    );
    context.setCustom(custom);

    this.setState({
      scenarios: scenarios,
    });
  }

  private printPath(path: number[]) {
    const context = this.context;
    let names: string[] = [];
    path.forEach((el) => {
      this.props.allLevels.forEach((found) => {
        if (el === found.id)
          names.push(context.custom[context.language].levels.name[found.id]);
      });
    });
    return <span>{names.reverse().join(" \u2190 ")}</span>;
  }

  /**
   * Genera un id univoco per lo scenario nuovo che viene creato. Prende l'id dell'ultimo scenario e aggiunge 1.
   * TOFIX id "univoco"
   * @param scenarios lista degli scenari in cui aggiungere un id
   */
  private generateId(scenarios: ScenarioInterface[]) {
    const scenarios_filtered = scenarios.filter((val) => val.readOnly !== true);
    if (scenarios_filtered.length < 1) {
      return 1000;
    } else {
      const ids = scenarios_filtered.map((val) => val.id);
      const max = Math.max(...ids) + 1;
      return max;
    }
  }

  /**
   * Crea uno scenario vuoto che verrà usato nella pagina "creazione scenario"
   */
  private newScenario() {
    const newScen: ScenarioInterface = {
      id: this.generateId(this.props.scenarios),
      newScenario: true,
      name: "",
      description: "",
      favourite: false,
      devices: [],
      readOnly: false,
    };
    return newScen;
  }

  public render() {
    const context: IContext = this.context;
    let id = this.props.match.params.id;
    if (id) {
      if (id === "new") {
        const scenario = context.scenario || this.newScenario();

        return (
          <ScenarioForm
            scenario={scenario}
            handleCancel={this.handleCancel}
            handleExecute={this.handleExecute}
            modifyScenario={this.modifyScenario}
            toDevices={this.props.toDevices}
            allDevices={this.props.allDevices}
            printPath={this.printPath}
            new={true}
            addNewScenario={this.addNewScenario}
          />
        );
      } else {
        const scenario = this.props.scenarios.find(
          (a) => a.id === Number.parseInt(id)
        );
        if (scenario) {
          if (this.state.edit) {
            return (
              <ScenarioForm
                scenario={scenario}
                handleCancel={this.handleCancel}
                handleExecute={this.handleExecute}
                modifyScenario={this.modifyScenario}
                toDevices={this.props.toDevices}
                allDevices={this.props.allDevices}
                printPath={this.printPath}
                new={false}
                addNewScenario={this.addNewScenario}
              />
            );
          } else {
            return (
              <ScenarioShow
                scenario={scenario}
                handleIsModify={this.handleIsModify}
                handleDelete={this.handleDelete}
                handleExecute={this.handleExecute}
                printPath={this.printPath}
              />
            );
          }
        } else {
          return <Redirect to={"/scenarios"} />;
        }
      }
    } else {
      return (
        <ScenarioHome
          scenarios={this.props.scenarios}
          editForm={this.editForm}
          handleExecute={this.handleExecute}
        />
      );
    }
  }
}

ScenarioContainer.contextType = Context;
