import * as React from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { Context, IContext } from '../../../Context';
import { Thermofan } from '../../../Interface';

interface ThermoWidgetProps {
    deviceID: number;
    delete: Function;
}
export const Thermometer: React.SFC<ThermoWidgetProps> = (props) => {
    const context : IContext = React.useContext(Context);

    const device: Thermofan = context.allDevices.find(
        (device) => device.id === props.deviceID,
    ) as Thermofan;
    return (
        <>
            <Card.Header>
                {context.custom[context.language].devices.name[props.deviceID]}
                <Dropdown
                    drop="down"
                    title="Menu"
                    id="dropdown-add-widget"
                    key="up"
                    className="float-right"
                >
                    <Dropdown.Toggle
                        id="dropdown-custom-2"
                        className="widget-option-button"
                    >
                        <i className="fas fa-ellipsis-v" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="widget-option">
                        <Dropdown.Item
                            className="widget-option-item"
                            eventKey="1"
                            onClick={() => props.delete()}
                        >
                            {
                                context.i18n[context.language].widgets.general
                                    .delete
                            }
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Card.Header>
            <Card.Body>
                <h2 style={{ fontVariantNumeric: 'tabular-nums' }}>
                    {device.thermofan.setpoint &&
                        device.thermofan.setpoint.value}
                    ˚C
                </h2>
            </Card.Body>
        </>
    );
};
