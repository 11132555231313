import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as React from "react";

import { Layout } from "./components/Layout/Layout";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";

// Gestione routes
// TODO: passa file linguaggio
export const Routes = () => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <ApiAuthorizationRoutes />
        <Layout />
    </MuiPickersUtilsProvider>
);
