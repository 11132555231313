import {
  AppBar,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  createStyles,
  IconButton,
  Link,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ArrowBack, Menu, Save, Star, StarBorder } from "@material-ui/icons";
import Axios from "axios";
import React, { FunctionComponent, useContext, useEffect } from "react";
import { Context } from "../../Context";
import { Custom, SubLevel } from "../../Interface";
import history from "../history";
import { EditableText } from "../Utilities/EditableText";
import { MapContext } from "./MapContext";
import { RenderLevelText } from "./RenderLevelText";
import { RenderMap } from "./RenderMap";

interface Props {
  level: SubLevel;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      maring: theme.spacing(1),
      height: "100%",
      float: "right",
    },
  })
);

export const RenderLevel: FunctionComponent<Props> = (props) => {
  const { level } = props;
  const context = useContext(Context);
  const { path, favoritePath, setFavorite, scenario, toScenario, textMode } =
    useContext(MapContext);
  const custom = context.custom;
  const name = custom[context.language].levels.name[level.id];
  const theme = useTheme();
  const classes = useStyles(theme);
  const mobile = useMediaQuery("(max-width: 769px)");
  const editMap: boolean =
    sessionStorage.getItem("editMap") === "true" || false;
  const isFavorite = JSON.stringify(path) === JSON.stringify(favoritePath);
  const updateCustomName = (name: string) => {
    if (name !== "") {
      const custom = context.custom;
      const toSend: Custom = {
        ...custom,
        [context.language]: {
          ...custom[context.language],
          levels: {
            ...custom[context.language].levels,
            name: {
              ...custom[context.language].levels.name,
              [level.id]: name,
            },
          },
        },
      };
      Axios.post("/apicustom", toSend);
    }
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          {mobile ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => context.setIsSidebarOpen(true)}
            >
              <Menu />
            </IconButton>
          ) : (
            path.length > 1 && (
              <IconButton
                edge="start"
                onClick={() =>
                  history.push("/devices/" + path.slice(0, -1).join("/"))
                }
                className={classes.fab}
              >
                <ArrowBack />
              </IconButton>
            )
          )}
          <Typography variant="h5" style={{ flexGrow: 1 }}>
            <EditableText
              value={name}
              editable={editMap}
              onSave={updateCustomName}
            />
            <Checkbox
              icon={<StarBorder />}
              className="ml-2"
              onChange={() => setFavorite(path)}
              color="primary"
              checkedIcon={<Star />}
              checked={isFavorite}
              name="checkedH"
            />
          </Typography>
          {mobile && path.length > 1 && (
            <IconButton
              edge="start"
              onClick={() =>
                history.push("/devices/" + path.slice(0, -1).join("/"))
              }
              className={classes.fab}
            >
              <ArrowBack />
            </IconButton>
          )}
        </Toolbar>
        <Toolbar>
          <Breadcrumbs separator="›" style={{ marginBottom: "1em" }}>
            {path.slice(0, -1).map((id, index) => {
              const name = custom[context.language].levels.name[id];
              const key = `level-${level.id}-breadcrumb-${id}`;
              const newPath = path.slice(0, index + 1);
              return (
                <Link
                  key={key}
                  onClick={() => history.push("/devices/" + newPath.join("/"))}
                  style={{ cursor: "pointer" }}
                >
                  {name}
                </Link>
              );
            })}
            <Typography color="textPrimary">{name}</Typography>
          </Breadcrumbs>
        </Toolbar>
      </AppBar>
      <Box
        padding={theme.spacing(0.5)}
        flexGrow="1"
        minHeight="0px"
        position="relative"
      >
        {context.scenario !== undefined && (
          <Box marginBottom={2}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<Save />}
              onClick={() => {
                if (scenario) {
                  context.setLock(false);
                  toScenario(scenario);
                }
              }}
            >
              {context.i18n[context.language].devices.saveScenario}
            </Button>
          </Box>
        )}
        {textMode ? (
          <RenderLevelText
            level={level}
            navigateTo={(id) =>
              history.push("/devices/" + [...path, id].join("/"))
            }
          />
        ) : (
          <RenderMap
            level={level}
            navigateTo={(id) =>
              history.push("/devices/" + [...path, id].join("/"))
            }
          />
        )}
      </Box>
    </Box>
  );
};
