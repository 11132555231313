import {
    Container,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
} from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';

import { Context } from '../../Context';
import { Alarm, Device } from '../../Interface';
import { DeviceIcon } from '../Devices/DeviceIcon';
import { TitleBar } from '../Layout/TitleBar';
import { AlarmDialog } from './AlarmDialog';
import { AlarmHistory } from './AlarmHistory';

// this.Props del componente
interface AlarmContainerProps {
    alarms: Alarm[]; // Lista di allarmi
}

interface AlarmContainerState {
    dialog: boolean;
    currentAlarm?: Alarm;
    history: boolean;
}

// Container che si occupa della pagina degli allarmi. Al suo interno, viene visualizzato il menu laterale e
// la pagina degli allarmi.
export class AlarmContainer extends React.Component<
    AlarmContainerProps,
    AlarmContainerState
    > {
    constructor(props: AlarmContainerProps) {
        super(props);

        this.state = {
            dialog: false,
            history: false,
        };

        this.setAlarm = this.setAlarm.bind(this);
        this.setOpen = this.setOpen.bind(this);
    }

    componentDidUpdate(oldProps: AlarmContainerProps) {
        if (JSON.stringify(oldProps) !== JSON.stringify(this.props)) {
            const alarm = this.state.currentAlarm;
            if (alarm) {
                const newAlarm = this.props.alarms.find(
                    (a) => a.id === alarm.id,
                );
                if (newAlarm) {
                    this.setState({ currentAlarm: newAlarm });
                }
            }
        }
    }

    setAlarm(alarm?: Alarm) {
        this.setState({
            currentAlarm: alarm,
        });
    }

    setOpen(dialog: boolean) {
        this.setState({
            dialog,
        });
    }

    render() {
        const context = this.context;
        return (
            <React.Fragment>
                {!this.state.history ? (
                    <Container>
                        <TitleBar
                            title={context.i18n[context.language].alarms.title}
                        />
                        <Paper>
                            <Toolbar className="justify-content-between">
                                <Typography variant="h6" id="tableTitle">
                                    {
                                        context.i18n[context.language].alarms
                                            .allAlarms
                                    }
                                </Typography>

                                <IconButton
                                    onClick={() =>
                                        this.setState({ history: true })
                                    }
                                >
                                    <i className="fas fa-history" />
                                </IconButton>
                            </Toolbar>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">
                                            {
                                                context.i18n[context.language]
                                                    .alarms.table.device
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                context.i18n[context.language]
                                                    .alarms.table.name
                                            }
                                        </TableCell>
                                        <TableCell className="ellipsis">
                                            {
                                                context.i18n[context.language]
                                                    .alarms.table.description
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                context.i18n[context.language]
                                                    .alarms.table.status
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                context.i18n[context.language]
                                                    .alarms.table.date
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.alarms
                                        .map((alarm) => {
                                            let status = 2;

                                            if (alarm.date && !alarm.dateSeen) {
                                                status = 2;
                                            } else if (
                                                alarm.status &&
                                                alarm.date &&
                                                alarm.dateSeen
                                            ) {
                                                status = 1;
                                            } else {
                                                status = 0;
                                            }

                                            return {
                                                alarm,
                                                status,
                                            };
                                        })
                                        .sort((a, b) => b.status - a.status)
                                        .map(({ alarm, status }) => {
                                            const device = context.allDevices.find(
                                                (device: Device) =>
                                                    device.id ===
                                                    alarm.deviceId,
                                            );
                                            let icon;
                                            let tempCol = 'red';

                                            switch (status) {
                                                case 2:
                                                    tempCol = '#ff2929'; //red
                                                    break;
                                                case 1:
                                                    tempCol = '#ffb500'; //yellow
                                                    break;
                                                case 0:
                                                    tempCol = 'gray';
                                                    break;
                                            }

                                            let style = {
                                                color: tempCol,
                                            };

                                            if (device) {
                                                icon = (
                                                    <DeviceIcon
                                                        default="far fa-bell"
                                                        status={1}
                                                        icons={device.icons}
                                                        className={'fa-2x'}
                                                        style={style}
                                                    />
                                                );
                                            }
                                            return (
                                                <TableRow
                                                    key={alarm.id}
                                                    selected={
                                                        alarm.status === true
                                                    }
                                                    onClick={() => {
                                                        this.setAlarm(alarm);
                                                        this.setOpen(true);
                                                    }}
                                                >
                                                    <TableCell align="center">
                                                        {icon}
                                                    </TableCell>
                                                    <TableCell >
                                                        {alarm.almdisable ?
                                                            <Typography style={{fontWeight: 200, fontStyle: "italic"}}>
                                                                {
                                                                    context.custom[
                                                                        context.language
                                                                        ].alarms.name[
                                                                        alarm.id
                                                                        ]
                                                                }
                                                            </Typography> :
                                                            <Typography>
                                                                {
                                                                    context.custom[
                                                                        context.language
                                                                        ].alarms.name[
                                                                        alarm.id
                                                                        ]
                                                                }
                                                            </Typography>
                                                        }

                                                    </TableCell>
                                                    <TableCell className="ellipsis">
                                                        <span>
                                                            {
                                                                context.custom[
                                                                    context
                                                                        .language
                                                                ].alarms
                                                                    .description[
                                                                alarm.id
                                                                ]
                                                            }
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        {alarm.status === true
                                                            ? 'ON'
                                                            : 'OFF'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {alarm.date
                                                            ? moment
                                                                .unix(
                                                                    alarm.date,
                                                                )
                                                                .format(
                                                                    'L LTS',
                                                                )
                                                            : '-'}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </Paper>
                        {this.state.currentAlarm && (
                            <AlarmDialog
                                onClose={() => {
                                    this.setAlarm(undefined);
                                    this.setOpen(false);
                                }}
                                open={this.state.dialog}
                                alarm={this.state.currentAlarm}
                                onHistory={() => {
                                    this.setState({ history: true });
                                }}
                            />
                        )}
                    </Container>
                ) : this.state.currentAlarm ? (
                    <AlarmHistory
                        allAlarms={this.props.alarms}
                        back={() =>
                            this.setState({
                                history: false,
                                currentAlarm: undefined,
                            })
                        }
                        id={this.state.currentAlarm.id}
                    />
                ) : (
                            <AlarmHistory
                                allAlarms={this.props.alarms}
                                back={() =>
                                    this.setState({
                                        history: false,
                                        currentAlarm: undefined,
                                    })
                                }
                            />
                        )}
            </React.Fragment>
        );
    }
}

AlarmContainer.contextType = Context;
