import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    IconButton,
    TextField,
    Switch,
    Input,
    Grid, Typography
} from '@material-ui/core';
import SwitchBase from '@material-ui/core/internal/SwitchBase';
import Axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { FormControl } from 'react-bootstrap';
// import { Switch } from 'react-router-dom';

import { Context, IContext } from '../../Context';
import { Alarm } from '../../Interface';
import { DeviceIcon } from '../Devices/DeviceIcon';

// Props del componente
interface AlarmDialogProps {
    alarm: Alarm; // Lista di allarmi
    open: boolean;
    onClose: () => void;
    onHistory: () => void;
}
async function saveEdit(
    alarm: Alarm,
    newName: string,
    newDescription: string,
    sendby: string | null,
    delay: number,
    almdisable: boolean,
    context: IContext,
) {
    const toSend = {
        ...context.custom,
        [context.language]: {
            ...context.custom[context.language],
            alarms: {
                name: {
                    ...context.custom[context.language].alarms.name,
                    [alarm.id]: newName,
                },
                description: {
                    ...context.custom[context.language].alarms.description,
                    [alarm.id]: newDescription,
                }
            },
        },
    };

    context.setCustom(toSend);

    const newAlarm: Alarm = {
        ...alarm,
        sendby,
        delay,
        almdisable
    };

    Axios.post('/apialarms', newAlarm)
        .then((res) => {
            if (res) {
                console.log(res);
            }
        })
        .catch((err) => {
            console.log(err);
        });
}

async function seeAlarm(alarm: Alarm) {
    const newAlarm: Alarm = {
        ...alarm,
        dateSeen: moment().unix(),
        // dateSeen: null,
    };

    Axios.post('/apialarms', newAlarm)
        .then((res) => {
            if (res) {
                console.log('Alarm seen');
            }
        })
        .catch((err) => {
            console.log(err);
        });
}

async function unseeAlarm(alarm: Alarm) {
    const newAlarm: Alarm = {
        ...alarm,
        // dateSeen: moment().unix(),
        dateSeen: null,
    };

    console.log(newAlarm);

    Axios.post('/apialarms', newAlarm)
        .then((res) => {
            if (res) {
                console.log('Alarm seen');
            }
        })
        .catch((err) => {
            console.log(err);
        });
}

export const AlarmDialog: React.SFC<AlarmDialogProps> = (props) => {
    const context: IContext = React.useContext(Context);

    const [editing, setEdit] = React.useState(false);
    const [name, setName] = React.useState(
        context.custom[context.language].alarms.name[props.alarm.id],
    );
    const [description, setDesctiption] = React.useState(
        context.custom[context.language].alarms.description[props.alarm.id],
    );
    const [sendby, setSendBy] = React.useState(
        props.alarm.sendby,
    );
    const [almdisable, setAlmDisable] = React.useState(
        props.alarm.almdisable,
    );
    const [delay, setDelay] = React.useState(
        props.alarm.delay,
    );
    const device = context.allDevices.find(
        (device) => device.id === props.alarm.deviceId,
    );
    let color = '#ff2929'; //red

    if (props.alarm.date && !props.alarm.dateSeen) {
        color = '#ff2929'; //red
    } else if (props.alarm.status && props.alarm.date && props.alarm.dateSeen) {
        color = '#ffb500'; //yellow
    } else {
        color = 'grey';
    }
    const style = {
        color,
    };
    let icon;
    if (device) {
        icon = (
            <DeviceIcon
                default="far fa-bell"
                status={1}
                icons={device.icons}
                className={'fa-2x mr-3'}
                style={style}
            />
        );
    }

    const handleDelayChange = event => {
        const value = Math.max(0, Math.min(3600, Number(event.target.value)));
        console.log(value)
        setDelay(value);
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth>
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {icon}
                    {editing ? (
                        <TextField
                            label={
                                context.i18n[context.language].alarms.table.name
                            }
                            fullWidth
                            value={name}
                            onChange={(el) => setName(el.target.value)}
                        />
                    ) : (
                        context.custom[context.language].alarms.name[
                            props.alarm.id
                            ]
                    )}
                    <div className="flex-fill" />
                    <IconButton onClick={() => props.onHistory()}>
                        <i className="fas fa-history" />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                {/* <FormControlLabel
                    control={
                        <Checkbox
                            checked={Boolean(props.alarm.dateSeen)}
                            onChange={() => seeAlarm(props.alarm)}
                            value="seen"
                            color="primary"
                        />
                    }
                    label={context.i18n[context.language].alarms.dialog.checked}
                /> */}
                <h4>
                    {context.i18n[context.language].alarms.table.description}
                </h4>
                {editing ? (
                    <TextField
                        fullWidth
                        multiline
                        label={
                            context.i18n[context.language].alarms.table
                                .description
                        }
                        value={description}
                        onChange={(el) => setDesctiption(el.target.value)}
                    />
                ) : (
                    <span style={{ whiteSpace: 'pre-line' }}>
                            {
                                context.custom[context.language].alarms.description[
                                    props.alarm.id
                                    ]
                            }
                        </span>
                )}
                <br />
                <br />
                {editing ? (
                    <>
                        <h4>{ context.i18n[context.language].alarms.table.date}</h4>
                        {props.alarm.date ? moment.unix(props.alarm.date).format('L - LTS'): '-'}
                            <br />
                        <br/>
                        <h4>
                            {context.i18n[context.language].alarms.dialog.notifications}
                        </h4>
                    </>
                ) : (
                    <>
                        <h4>{ context.i18n[context.language].alarms.table.date}</h4>
                        {props.alarm.date ? moment.unix(props.alarm.date).format('L - LTS'): '-'}
                    </>
                )
                }
                {editing ? (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={Boolean(sendby)}
                                            onChange={(el) => setSendBy(el.target.checked ? "MAIL" : null)}
                                            color='primary'
                                        />
                                    }
                                    label={
                                        context.i18n[context.language].alarms.dialog.send
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={almdisable}
                                            onChange={(el) => setAlmDisable(el.target.checked)}
                                            color='primary'
                                        />
                                    }
                                    label={
                                        context.i18n[context.language].alarms.dialog.disable
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <h4>
                                    {context.i18n[context.language].alarms.dialog.delay}
                                </h4>
                                <Typography style={{fontWeight: 100, fontStyle: "italic", color:"gray"}}>
                                    {context.i18n[context.language].alarms.dialog.delayRange}
                                </Typography>
                                <TextField
                                    value={delay}
                                    type="number"
                                    style={{minWidth: '60px'}}
                                    InputProps={{
                                        inputProps: {
                                            max: 3600, min: 0
                                        }
                                    }}
                                    color="primary"
                                    onChange={handleDelayChange}
                                />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        <br/>
                        <br/>
                    {almdisable ? (
                        <i className="fas fa-bell-slash" style={{color:"gray"}}></i>
                    ) : (
                        <i className="fas fa-bell" style={{color:"gray"}}></i>
                    )
                    }
                    {Boolean(sendby) ? (
                        <i className="fas fa-envelope" style={{color:"gray", marginLeft:"10px"}}></i>
                        ) : (
                            <>
                            </>
                        )
                    }
                    </>
                )
                }

            </DialogContent>
            <DialogActions>
                {props.alarm.persistent &&
                    props.alarm.date &&
                    (color === '#ff2929' ? (
                        <Button
                            className="ml-2"
                            onClick={() => seeAlarm(props.alarm)}
                        >
                            {context.i18n[context.language].alarms.dialog.ack}
                        </Button>
                    ) : (
                        <Button
                            className="float-left"
                            onClick={() => unseeAlarm(props.alarm)}
                        >
                            {context.i18n[context.language].alarms.dialog.deack}
                        </Button>
                    ))}
                <div className="flex-fill" />
                {editing ? (
                    <>
                        <Button onClick={() => setEdit(false)}>
                            {
                                context.i18n[context.language].alarms.dialog
                                    .cancel
                            }
                        </Button>
                        <Button
                            onClick={() => {
                                saveEdit(
                                    props.alarm,
                                    name,
                                    description,
                                    sendby,
                                    delay,
                                    almdisable,
                                    context,
                                );
                                setEdit(false);
                            }}
                            color="primary"
                        >
                            {context.i18n[context.language].alarms.dialog.save}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button onClick={() => setEdit(true)}>
                            {context.i18n[context.language].alarms.dialog.edit}
                        </Button>
                        <Button onClick={props.onClose}>
                            {context.i18n[context.language].alarms.dialog.close}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};
