import { Box, Fab, useTheme, withStyles } from "@material-ui/core";
import { Cancel, Edit, Save } from "@material-ui/icons";
import Axios from "axios";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Context } from "../../Context";
import { objectLayout, SubLevel } from "../../Interface";
import { DeviceMap } from "./Map/DeviceMap";
import { MapContext } from "./MapContext";

interface Props {
  level: SubLevel;
  navigateTo: (id: number) => void;
}

export const RenderMap: FunctionComponent<Props> = (props) => {
  const { level } = props;
  const [stateLevel, setStateLevel] = useState(level);
  const { setSwipe } = useContext(MapContext);
  const context = useContext(Context);
  const theme = useTheme();
  const [edit, setEdit] = useState(false);
  const editMap: boolean =
    sessionStorage.getItem("editMap") === "true" || false;
  const ErrorFab = withStyles({
    root: {
      backgroundColor: theme.palette.error.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
      "&:active": {
        backgroundColor: theme.palette.error.light,
      },
    },
  })(Fab);
  const SuccessFab = withStyles({
    root: {
      backgroundColor: theme.palette.success.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.success.dark,
      },
      "&:active": {
        backgroundColor: theme.palette.success.light,
      },
    },
  })(Fab);

  useEffect(() => {
    setStateLevel(level);
  }, [level]);

  useEffect(() => {
    if (edit) setSwipe(false);
    else setSwipe(true);
  }, [edit]);

  function saveEdit() {
    const devices = document.getElementsByClassName(
      "device-pill level-" + level.id
    );
    const deviceCopy: objectLayout[] = JSON.parse(
      JSON.stringify(level.devices)
    );
    for (const i in devices) {
      const device = devices[i];
      if (device.getAttribute) {
        const index = parseInt(device.getAttribute("data-index") || "");
        const [top, left] = device.getAttribute("style")?.split(";") || [];
        // const showLabel = device.getAttribute('data-showlabel') === 'true';
        const topNumber = parseFloat(top.replace(/[^\d.-]/g, ""));
        const leftNumber = parseFloat(left.replace(/[^\d.-]/g, ""));
        // if (showLabel !== level.devices[index].layout.showLabel) {
        //   deviceCopy[index].layout.showLabel = showLabel;
        // }
        if (topNumber !== level.devices[index].layout.top) {
          deviceCopy[index].layout.top = topNumber;
        }
        if (leftNumber !== level.devices[index].layout.left) {
          deviceCopy[index].layout.left = leftNumber;
        }
      }
    }

    const levels = document.getElementsByClassName(
      "level-pill level-" + level.id
    );
    const levelsCopy: objectLayout[] = JSON.parse(
      JSON.stringify(level.subLevels)
    );
    for (const i in levels) {
      const subLevel = levels[i];
      if (subLevel.getAttribute) {
        const index = parseInt(subLevel.getAttribute("data-index") || "");
        const [top, left] = subLevel.getAttribute("style")?.split(";") || [];
        const showLabel = subLevel.getAttribute("data-showlabel") === "true";
        const topNumber = parseFloat(top.replace(/[^\d.-]/g, ""));
        const leftNumber = parseFloat(left.replace(/[^\d.-]/g, ""));
        if (showLabel !== level.subLevels[index].layout.showLabel) {
          levelsCopy[index].layout.showLabel = showLabel;
        }
        if (topNumber != level.subLevels[index].layout.top) {
          levelsCopy[index].layout.top = topNumber;
        }
        if (leftNumber != level.subLevels[index].layout.left) {
          levelsCopy[index].layout.left = leftNumber;
        }
      }
    }

    let newLevel: SubLevel = {
      ...level,
      devices: deviceCopy,
      subLevels: levelsCopy,
    };
    Axios.post("apilevels", newLevel)
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
    setEdit(false);
    context.setLock(false);
  }

  function discardEdit() {
    setStateLevel({ ...level, subLevels: [], devices: [] });
    setTimeout(() => setStateLevel({ ...level }), 100);
    setEdit(false);
    context.setLock(false);
  }

  return (
    <>
      {editMap && (
        <Box
          textAlign="right"
          marginBottom={1}
          position="absolute"
          right="20px"
          zIndex={1100}
          top="-100px"
        >
          {!edit ? (
            <Fab onClick={() => setEdit(true)} size="small">
              <Edit />
            </Fab>
          ) : (
            <Box>
              <SuccessFab
                onClick={() => saveEdit()}
                size="small"
                style={{ marginRight: theme.spacing(1) }}
              >
                <Save />
              </SuccessFab>
              <ErrorFab onClick={() => discardEdit()} size="small">
                <Cancel />
              </ErrorFab>
            </Box>
          )}
        </Box>
      )}
      <DeviceMap currentLevel={stateLevel} editing={edit} saveEdit={saveEdit} />
    </>
  );
};
