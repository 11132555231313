import * as React from 'react';

interface EditableTextProps {
    value: string;
    editable: boolean;
    onSave: Function;
    onCancel?: Function;
}

interface EditableTextState {
    value: string;
    editing: boolean;
}
export class EditableText extends React.Component<
    EditableTextProps,
    EditableTextState
    > {
    private textInput: React.RefObject<HTMLInputElement>;
    constructor(props: EditableTextProps) {
        super(props);
        this.state = {
            value: this.props.value,
            editing: false,
        };
        this.enableEditing = this.enableEditing.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.textInput = React.createRef();
    }

    public componentDidUpdate(prevProps: EditableTextProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                value: this.props.value,
            });
        }
    }

    public enableEditing(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        this.setState({ editing: true }, () => {
            if (this.textInput.current) this.textInput.current.focus();
        });
    }

    public onChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.currentTarget.value;
        this.setState({ value });
    }

    public onBlur(event: React.FocusEvent<HTMLInputElement>) {
        this.props.onSave(this.state.value);
        this.setState({ editing: false });
    }

    public onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Escape') {
            this.setState({
                editing: false,
                value: this.props.value,
            });
        }
        if (event.key === 'Enter') {
            this.setState({ editing: false });
            this.props.onSave(this.state.value);
        }
    }

    public render() {
        if (this.props.editable) {
            if (!this.state.editing) {
                return (
                    <span
                        style={{ zIndex: 105 }}
                        onClick={(event) => this.enableEditing(event)}
                    >
                        {this.props.value}
                    </span>
                );
            } else {
                return (
                    <input
                        id="input-text"
                        type="text"
                        value={this.state.value}
                        ref={this.textInput}
                        onChange={this.onChange}
                        onKeyDown={this.onKeyDown}
                        onBlur={this.onBlur}
                        style={{ zIndex: 105 }}
                    />
                );
            }
        } else {
            return this.props.value || '';
        }
    }
}
