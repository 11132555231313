import React, { FunctionComponent, useState } from 'react';

interface Props {
  id?: string;
  onClick?: (evt: React.PointerEvent) => void;
  onHold?: (evt: React.PointerEvent) => void;
  timeout?: number;
}

export const Holdable: FunctionComponent<Props> = ({
  id,
  onClick,
  onHold,
  children,
  timeout = 500,
}) => {
  const [timer, setTimer] = useState<number | null>(null);

  function onPointerDown(evt: React.PointerEvent) {
    const event = { ...evt }; // convert synthetic event to real object
    const timeoutId = window.setTimeout(timesup.bind(null, event), timeout);
    setTimer(timeoutId);
  }

  function onPointerUp(evt: React.PointerEvent) {
    const event = { ...evt }; // convert synthetic event to real object
    if (timer !== null) {
      window.clearTimeout(timer);
      setTimer(null);
      if (onClick) onClick(event);
    }
  }

  function timesup(evt: React.PointerEvent) {
    const event = { ...evt }; // convert synthetic event to real object
    setTimer(null);
    if (onHold) onHold(event);
  }

  return (
    <div onPointerDown={onPointerDown} onPointerUp={onPointerUp} id={id}>
      {children}
    </div>
  );
};
