import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ArrowBack, ArrowForward, PlayArrow, Sync } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import Axios from "axios";
import moment, { Moment } from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipPayload,
  XAxis,
  YAxis,
} from "recharts";

import { Context, IContext } from "../../Context";
import { Device } from "../../Interface";

interface Props {
  open: boolean;
  onClose: () => void;
  device: Device;
  udm?: string;
  valuePath: string;
}

interface ChartData {
  timestamp: number;
  value: number;
}

var direction: number = 0;

export const ValueChart: React.FunctionComponent<Props> = (props) => {
    const theme: Theme = useTheme();
    const { open, onClose, device, valuePath } = props;
    const context: IContext = useContext(Context);
    const [data, setData] = useState<ChartData[]>([]);
    const [loading, setLoading] = useState(true);
    const [running, setRunning] = useState(true);
    const [reloadData, setReloadData] = useState(false);
    const [startDate, setStartDate] = useState<Moment | undefined>(
        moment().hour(0).minutes(0).seconds(0)
    );
    const [endDate, setEndDate] = useState<Moment | undefined>(
        moment().hour(23).minutes(59).seconds(59)
    );
    const name = context.custom[context.language].devices.name[props.device.id];

    let args = "";
    if (startDate) args += `&from=${startDate.unix()}`;
    if (endDate) args += `&to=${endDate.unix()}`;
    const domainMin = startDate ? moment(startDate).startOf("day").unix() : "auto";
    const domainMax = endDate ? moment(endDate).endOf("day").unix() : "auto"; 


  useEffect(() => {
    if (open) {
        (async () => {
            setReloadData(false);
            setLoading(true);
            setData([]);
        let response = await Axios.get(
            `/apihistory?id=${device.id}&property=${valuePath}&direction=${direction}${args}`
        );
          setData(response.data);

          if (response.data.length > 0) {
              /**
               * Verifico se i dati ritornati sono fuori dall'intervallo richiesto.
               * Infatti l'API se non trova dati nell'intervallo richiesto prova a cercarne precedenti a startDate o successivi a endDate a seconda della direzione di ricerca. 
               * La direzione di ricerca - direction - assume il valore -1 se si ricerca il giorno precedente, 1 se il giorno successivo, 0 se l'intervallo � impostato coi datapicker.
               * In quest'ultimo caso, nel caso non ci siano dati, non � effettuata nessuna ulteriore ricerca. La ricerca di altri dati � fatta solo con le ricerce +/- un giorno (pulsanti <,>).
               * Se l'API trova dei dati, sono riportati i dati dell'intera ultima giornata trovata.
               * Si reimpostano quindi startDate ed endDate per il nuovo intervallo che contiene dei dati, in modo tale da avere i dataPicker e l'asse X del grafico corretti.
               * Se invece i dati ritornati sono nell'intervallo di ricerca, non � eseguita alcuna modifica alle date dell'intervallo stesso.
               */
              var t1 = response.data[0].timestamp;
              var t2 = response.data[response.data.length - 1].timestamp;
              if (((startDate) && (t1 < startDate.unix()) && (t2 < startDate.unix())) ||
                  ((endDate) && (t1 > endDate.unix()) && (t2 > endDate.unix())))
              {
                  var tstart = new Date((new Date(t1 * 1000)).setHours(0, 0, 0));
                  var tend = new Date((new Date(t1 * 1000)).setHours(23, 59, 59));
                  setStartDate(moment(tstart));
                  setEndDate(moment(tend));                      
              }
          }

        setLoading(false);
      })();
    }
  }, [open, running]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>{name}</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          <IconButton
            disabled={startDate === undefined}
            className="mr-2"
            onClick={() => {
                direction = -1;
                setStartDate(moment(startDate).subtract(1, "day"));
                setEndDate(moment(endDate).subtract(1, "day"));
                setRunning(!running);
            }}
          >
            <ArrowBack />
          </IconButton>

          <DatePicker
            label={context.i18n[context.language].programs.startDate}
            value={moment(startDate) || null}
            onChange={(newDate) => {
                direction = 0;                
                setStartDate(moment(newDate?.toDate()));
                if (moment(newDate?.toDate()).isAfter(moment(endDate))) {
                    setEndDate(moment(newDate?.toDate()).add(1, "day"));
                }
                /** setRunning(!running); */
                setReloadData(true);
                setData([]);             
            }}
            clearable
            format="dddd D MMMM"
            size="small"
            color="primary"
            className="mr-3"
            inputVariant="filled"
                  />
        <IconButton
            disabled={startDate === undefined}
            className="mr-3"
            onClick={() => {
                direction = 0;
                setRunning(!running);
            }}
        >
            <Sync />
         </IconButton>

          <DatePicker
            label={context.i18n[context.language].programs.endDate}
            value={endDate || null}
            onChange={(newDate) => {
                direction = 0;
                setEndDate(moment(newDate?.toDate()));
                /** setRunning(!running); */
                setReloadData(true);
                setData([]);
            }}
            clearable
            format="dddd D MMMM"
            size="small"
            color="primary"
            minDate={startDate}
            inputVariant="filled"
          />
          <IconButton
            disabled={startDate === undefined}
            className="ml-2"
            onClick={() => {
                direction = 1;
                setStartDate(moment(startDate).add(1, "day"));
                setEndDate(moment(endDate).add(1, "day"));
                setRunning(!running);
            }}
          >
            <ArrowForward />
          </IconButton>
        </Box>

        {data.length === 0 && !loading && !reloadData && (
          <Box
            minHeight={"60vh"}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="caption" color="textSecondary">
                {context.i18n[context.language].programs.nodata}
            </Typography>
          </Box>
         )}

        {data.length === 0 && !loading && reloadData && (
            <Box
                minHeight={"60vh"}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" color="textSecondary">
                          {context.i18n[context.language].programs.reloaddata}
                </Typography>
            </Box>
        )}

        {loading && (
          <Box
            minHeight={"60vh"}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}
        {data.length > 0 && (
          <Box height="60vh">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={data}
                margin={{ top: 20, right: 20, left: 20, bottom: 10 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="timestamp"
                  tick={{ fill: theme.palette.text.primary }}
                  tickFormatter={(tick) =>
                    moment(tick * 1000).format("DD/MM LT")
                  }
                  label={{ fontSize: "16pt" }}
                  type="number"
                  domain={[domainMin, domainMax]}
                  tickMargin={10}
                  tickCount={11}
                />
                <YAxis
                  label={{ fontSize: "16pt" }}
                  unit={props.udm}
                  tick={{ fill: theme.palette.text.primary }}
                />
                <Tooltip content={CustomTooltip} />
                <Area
                  dot={false}
                  type={"stepAfter"}
                  strokeWidth={2}
                  unit={props.udm}
                  dataKey="value"
                  stroke={theme.palette.primary.main}
                  fill={theme.palette.primary.main}
                  animationDuration={500}
                />
              </AreaChart>
            </ResponsiveContainer>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
              <Button onClick={props.onClose}>{context.i18n[context.language].programs.close}</Button>
      </DialogActions>
    </Dialog>
  );
};

const CustomTooltip: React.FunctionComponent = (params: any) => {
  return (
    <Paper elevation={4}>
      {params.payload?.map((payload: TooltipPayload) => {
        return (
          <Box
            padding={2}
            key={"value-chart-point" + params.label}
            textAlign="center"
          >
            <Typography color="primary" variant="subtitle2">
              {moment(params.label * 1000).format("DD/MM LT")}:
            </Typography>
            {payload.value} {payload.unit}
          </Box>
        );
      })}
    </Paper>
  );
};
