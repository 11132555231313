import {
  Box,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import * as React from "react";
import { Context, IContext } from "../../../Context";
import { Sensor } from "../../../Interface";
import { DeviceIcon } from "../../Devices/DeviceIcon";
import { DeviceModal } from "../../NewDevices/Modals/DeviceModal";

interface Props {
  device: Sensor;
  sendUpdate: Function;
  delete: Function;
  unsetDevice: Function;
}
export const SensorWidgetComponent: React.FunctionComponent<Props> = (
  props
) => {
  const context: IContext = React.useContext(Context);
  const [showPopup, setPopup] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const sensorValue = props.device.sensor.sensorValue;
  const sensorUdm = props.device.sensor.sensorUdm;
  const sensorTxtConvert = props.device.sensor.sensorTxtConvert;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => props.delete()}>
          {context.i18n[context.language].widgets.general.delete}
        </MenuItem>
        <MenuItem onClick={() => props.unsetDevice()}>
          {context.i18n[context.language].widgets.menu.changeDevice}
        </MenuItem>
      </Menu>
      <CardContent
        style={{
          flexShrink: 1,
          minHeight: 0,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: 1,
          paddingBottom: 0,
        }}
      >
        <Box
          position='absolute'
          top={theme.spacing(1)}
          right={theme.spacing(1)}
        >
          <IconButton onClick={handleClick} size='small'>
            <MoreVert />
          </IconButton>
        </Box>
        <Typography color='textSecondary' gutterBottom style={{ fontSize: 14 }}>
          {context.custom[context.language].devices.name[props.device.id]}
        </Typography>
        <div className='light-widget-container' onClick={() => setPopup(true)}>
          <div
            className={
              "light-widget-icon " +
              (!props.device.sensor.sensorActive
                ? "device-off"
                : "device-on color-accent")
            }
            style={{
              position: "relative",
            }}
          >
            {props.device.sensor.sensorDisconnect && (
              <i
                className='fas fa-slash fa-2x'
                style={{
                  position: "absolute",
                  left: "-8px",
                  color: "rgb(162 ,162,162)",
                }}
              />
            )}
            <DeviceIcon
              default='far fa-bell'
              status={props.device.sensor.sensorActive ? 1 : 0}
              icons={props.device.icons}
              className={"fa-3x"}
            />{" "}
            {props.device.sensor.sensorAlarm && (
              <i
                className='fas fa-exclamation text-danger'
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                }}
              />
            )}
          </div>
          {props.device.sensor.sensorValue !== undefined && (
            <div className='light-widget-status'>
              <div>
                {sensorValue !== undefined &&
                  (sensorTxtConvert ? (
                    <Box>
                      <Typography variant='h5' gutterBottom>
                        {sensorTxtConvert.find((e) => e.val === sensorValue)
                          ?.txt || ""}
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant='h4' gutterBottom>
                        {sensorValue} {sensorUdm !== undefined && sensorUdm}
                      </Typography>
                    </Box>
                  ))}
              </div>
            </div>
          )}
        </div>
      </CardContent>
      <DeviceModal
        device={props.device}
        open={showPopup}
        onClose={() => setPopup(false)}
        locked={props.device.readOnly}
      />
    </>
  );
};
