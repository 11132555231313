import { object } from 'prop-types';
import * as React from 'react';

import {
  Alarm,
  Custom,
  Device,
  Event,
  HomeConfig,
  Languages,
  MapImage,
  ScenarioInterface,
  SubLevel,
} from './Interface';

export interface IContext {
  theme: string;
  setTheme: Function;
  accent: string;
  setAccent: Function;
  lock: boolean;
  setLock: Function;
  i18n: any;
  custom: Custom;
  setCustom: Function;
  language: Languages;
  setLanguage: Function;
  allDevices: Device[];
  allLevels: SubLevel[];
  allScenarios: ScenarioInterface[];
  allPrograms: Event[];
  allAlarms: Alarm[];
  scenario?: ScenarioInterface;
  setScenario: Function;
  email?: string;
  isSidebarOpen: boolean;
  setIsSidebarOpen: Function;
  images: MapImage[];
  homeConfig: HomeConfig;
  setImages: (images: MapImage[]) => Promise<void>;
}

export const emptyLanguage = {
  devices: {
    name: {},
  },
  levels: {
    name: {},
  },
  alarms: {
    name: {},
    description: {},
  },
  scenarios: {
    name: {},
    description: {},
  },
  programs: {
    name: {},
    description: {},
  },
};

const context = React.createContext<IContext>({
  theme: '',
  setTheme: () => {},
  accent: '',
  setAccent: () => {},
  lock: false,
  setLock: () => {},
  i18n: {},
  custom: {
    en: emptyLanguage,
    it: emptyLanguage,
    de: emptyLanguage,
  },
  setCustom: () => {},
  language: 'en',
  setLanguage: () => {},
  allDevices: [],
  allLevels: [],
  allScenarios: [],
  allPrograms: [],
  allAlarms: [],
  setScenario: () => {},
  isSidebarOpen: false,
  setIsSidebarOpen: () => {},
  images: [],
  homeConfig: {
    devices: true,
    scenarios: true,
    programs: true,
    alarms: true,
    weather: true,
    settings: true,
    cleanScreen: true,
    lockScreen: true,
    textMode: true,
    languages: ['en'],
    languageDefault: 'en',
    alarmEmail: null
  },
  setImages: () => new Promise(() => {}),
});

export const ContextProvider = context.Provider;
export const ContextConsumer = context.Consumer;
export const Context = context;
