import Axios from 'axios';
import React from 'react';
import {
  Blind,
  Button as DeviceButton,
  Device,
  DeviceType,
  DimLight,
  Light,
  RgbLight,
  RotatingBlind,
  Scene,
  Sensor,
  Thermofan,
  Thermostat,
} from '../../../Interface';
import { BlindControl } from './Blind/BlindControl';
import { RotatingBlindControl } from './Blind/RotatingBlindControl';
import { ButtonControl } from './Button/ButtonControl';
import { DimLightControl } from './Light/DimLightControl';
import { LightControl } from './Light/LightControl';
import { RgbLightControl } from './Light/RgbLightControl';
import { SceneControl } from './Scene/SceneControl';
import { SensorControl } from './Sensor/SensorControl';
import { ThermofanControl } from './Thermostat/ThermofanControl';
import { ThermostatControl } from './Thermostat/ThermostatControl';

interface Props {
  device: Device;
}

export const DeviceContent: React.FunctionComponent<Props> = ({ device }) => {
  const updateDevice = (device: Device) => {
    Axios.post('/apidevices', device);
  };

  switch (device.type) {
    case DeviceType.switch:
    case DeviceType.light:
      return (
        <LightControl device={device as Light} updateDevice={updateDevice} />
      );
    case DeviceType.dimLight:
      return (
        <DimLightControl
          device={device as DimLight}
          updateDevice={updateDevice}
        />
      );
    case DeviceType.rgbLight:
      return (
        <RgbLightControl
          device={device as RgbLight}
          updateDevice={updateDevice}
        />
      );
    case DeviceType.blind:
      return (
        <BlindControl device={device as Blind} updateDevice={updateDevice} />
      );
    case DeviceType.rotatingBlind:
      return (
        <RotatingBlindControl
          device={device as RotatingBlind}
          updateDevice={updateDevice}
        />
      );
    case DeviceType.scene:
      return (
        <SceneControl device={device as Scene} updateDevice={updateDevice} />
      );
    case DeviceType.sensor:
      return (
        <SensorControl device={device as Sensor} updateDevice={updateDevice} />
      );
    case DeviceType.thermofan:
      return (
        <ThermofanControl
          device={device as Thermofan}
          updateDevice={updateDevice}
        />
      );
    case DeviceType.thermostat:
      return (
        <ThermostatControl
          device={device as Thermostat}
          updateDevice={updateDevice}
        />
      );
    case DeviceType.button:
      return (
        <ButtonControl
          device={device as DeviceButton}
          updateDevice={updateDevice}
        />
      );
    default:
      return <></>;
  }
};
