import {
  Badge,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import * as React from 'react';
import Ripple from 'react-material-ripple';
import { matchPath } from 'react-router';
import { Context, IContext } from '../../Context';
import { Alarm, HomeConfig } from '../../Interface';
import history from '../history';
import Layout  from './Layout'

interface SidebarContentProps {
  compactSidebar: boolean;
  setCompact: Function;
  homeConfig: HomeConfig;
  toggleSidebar: () => void;
}

function isAlarmRed(alarm: Alarm): number {
  if (alarm.date && !alarm.dateSeen) {
    return 1;
  }
  return 0;
}

const useStyle = makeStyles(() => ({
  primary: {
    fontSize: '18px',
  },
}));

export const SidebarContent: React.FunctionComponent<SidebarContentProps> = (
  props
) => {
  let config: (keyof HomeConfig)[] = [
    'devices',
    'scenarios',
    'programs',
    'alarms',
    'weather',
    'settings',
    'cleanScreen',
    'lockScreen',
  ];

  config = config.filter((page) => props.homeConfig[page]);
  const classes = useStyle();
  const pages: string[] = config;
  pages.unshift('home');

  const icons: any = {
    home: 'fa fa-home',
    devices: 'fa fa-laptop',
    scenarios: 'fa fa-image',
    programs: 'fa fa-calendar',
    alarms: 'fa fa-bell',
    weather: 'fa fa-cloud',
    settings: 'fa fa-cogs',
    cleanScreen: 'fa fa-hand-paper',
    lockScreen: 'fa fa-lock',
    refresh: 'fas fa-sync-alt',
  };

  const context: IContext = React.useContext(Context);
  const nAlarms = context.allAlarms.reduce((a, b) => a + isAlarmRed(b), 0);

  return (
    <List component='nav'>
      <ListItem
        onClick={() => props.setCompact(!props.compactSidebar)}
        style={{ minHeight: '50px' }}
      >
        <ListItemIcon>
          <em
            className={
              'collapse-icon fas fa-angle-left transition-all ' +
              (props.compactSidebar ? 'rot-180' : '')
            }
          />
        </ListItemIcon>
        <ListItemText classes={{ primary: classes.primary }}>
          {!props.compactSidebar &&
            context.i18n[context.language].menu.omnivision}
        </ListItemText>
      </ListItem>
      {pages.map((page, index) => {
        return (
          <ListItem
            button
            key={index}
            selected={
              !!matchPath(history.location.pathname, { path: `/${page}` })
            }
            onClick={() => {
              history.push('/' + page);
            }}
            style={{ minHeight: '50px' }}
          >
            <ListItemIcon>
              <Badge
                badgeContent={page === 'alarms' ? nAlarms : 0}
                color='error'
              >
                <em className={icons[page] + ' sidebar-icon-full'} />
              </Badge>
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.primary }}
              primary={
                !props.compactSidebar &&
                context.i18n[context.language].menu[page]
              }
            />
          </ListItem>
        );
      })}
      <ListItem
        onClick={() => {
            history.push('/');
            window.location.reload();
            // viene chiamata una funzione per richiedere il RefreshAll a tutti i services connessi.
            // Il RefreshAll avviene solo se in appsettings si ha "EnableRefreshAllServices": "true"
            let ly = new Layout(null);
            ly.ConnRefresh();
        }}
        style={{ minHeight: '50px' }}
      >
        <ListItemIcon>
          <em className={icons['refresh'] + ' sidebar-icon-full'} />
        </ListItemIcon>
        <ListItemText classes={{ primary: classes.primary }}>
          {!props.compactSidebar && context.i18n[context.language].menu.refresh}
        </ListItemText>
      </ListItem>
    </List>
  );
};
