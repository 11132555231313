import { createBrowserHistory } from 'history';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);
const i18nFile = require('../i18n/language.json');
const i18nStringa = JSON.stringify(i18nFile);
const i18n = JSON.parse(i18nStringa);

export default createBrowserHistory({
    getUserConfirmation(message, callback) {
        const language = localStorage.getItem('language') || 'en';
        const secondary = getComputedStyle(
            document.documentElement,
        ).getPropertyValue('--secondary');
        const accent = getComputedStyle(
            document.documentElement,
        ).getPropertyValue('--accent');
        MySwal.fire({
            title: i18n[language].swal.leavingPage,
            text: message,
            icon: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            background: secondary,
            confirmButtonColor: accent,
        }).then((result) => {
            if (result.value) {
                callback(true);
            } else {
                callback(false);
            }
        });
    },
});
