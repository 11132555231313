import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  Input,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Add, Lock, Remove } from "@material-ui/icons";
import Axios from "axios";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Custom,
  Device,
  DeviceType,
  PanelVisibilityEnum,
  QualityEnum,
  ScenarioInterface,
} from "../../../Interface";
import { EditableText } from "../../Utilities/EditableText";
import { MapContext } from "../MapContext";
import { Context } from "./../../../Context";
import { RenderDeviceIcon } from "./../RenderDeviceIcon";
import { DeviceChart } from "./DeviceChart";
import { LevelSelection } from "./LevelSelection";
import { DeviceContent } from "./DeviceContent";

interface DeviceModalProps {
  open: boolean;
  onClose: () => void;
  device: Device;
  toggleShowLabel?: () => void;
  showLabel?: boolean;
  onSave?: (device: Device) => void;
  locked?: boolean;
}

export const DeviceModal: FunctionComponent<DeviceModalProps> = (props) => {
  const {
    open,
    onClose,
    device,
    toggleShowLabel,
    showLabel,
    onSave,
    locked,
  } = props;
  const context = useContext(Context);
  const { scenario, setScenario, setSwipe } = useContext(MapContext);
  const [showLevelSelection, setShowLevelSelection] = useState(false);
  const editMap: boolean =
    sessionStorage.getItem("editMap") === "true" || false;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const name = context.custom[context.language].devices.name[device.id];

  useEffect(() => {
    if (setSwipe) setSwipe(!open);
  }, [open, setSwipe]);

  const updateCustomName = (name: string) => {
    if (device) {
      const custom = context.custom;
      const toSend: Custom = {
        ...custom,
        [context.language]: {
          ...custom[context.language],
          devices: {
            ...custom[context.language].devices,
            name: {
              ...custom[context.language].devices.name,
              [device.id]: name,
            },
          },
        },
      };
      Axios.post("/apicustom", toSend);
    }
  };

  function close() {
    onClose();
    if (onSave) onSave(device);
  }

  return (
    <Dialog
      onClose={close}
      open={
        device.panelVisibility !== PanelVisibilityEnum.PANEL_HIDE ? open : false
      }
      fullScreen={fullScreen}
      fullWidth
      maxWidth="xs"
      scroll="body"
    >
      <Box position="relative">
        <Box
          position="absolute"
          width="100%"
          height="100%"
          bgcolor="rgba(255,255,255,0.4)"
          zIndex={4}
          display={
            device.panelVisibility === PanelVisibilityEnum.PANEL_OPEN_R
              ? "block"
              : "none"
          }
        >
          <Icon
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              height: "30px",
            }}
          >
            <Lock />
          </Icon>
        </Box>
        {device && (
          <>
            <DialogTitle>
              <Box display="flex" alignItems="center">
                <RenderDeviceIcon device={device} className="fa-2x mr-3 mt-1" />
                <EditableText
                  value={name}
                  editable={editMap}
                  onSave={updateCustomName}
                />
                <Badge
                  color="error"
                  overlap="rectangle"
                  variant="dot"
                  invisible={
                    device.quality === QualityEnum.good ||
                    device.type === DeviceType.scene
                  }
                >
                  <Box height="1em" width="1em"></Box>
                </Badge>
              </Box>
            </DialogTitle>

            <DialogContent>
              {locked && <Lock />}
              <div
                style={
                  locked
                    ? {
                        pointerEvents: "none",
                        opacity: "0.4",
                        marginTop: theme.spacing(1),
                      }
                    : {}
                }
              >
                <DeviceContent device={device} />
              </div>
              {scenario !== undefined &&
                device.type !== DeviceType.scene &&
                device.type !== DeviceType.sensor && (
                  <Box margin={1}>
                    {scenario.devices.find((dev) => dev.id === device.id) ===
                    undefined ? (
                      <Button
                        onClick={() => {
                          if (setScenario)
                            setScenario({
                              ...scenario,
                              devices: [...scenario.devices, device],
                            });
                        }}
                        startIcon={<Add />}
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        {
                          context.i18n[context.language].devices.scenarioMode
                            .addToScenario
                        }
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          if (setScenario)
                            setScenario({
                              ...scenario,
                              devices: scenario.devices.filter(
                                (dev) => dev.id !== device.id
                              ),
                            });
                        }}
                        startIcon={<Remove />}
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        {
                          context.i18n[context.language].devices.scenarioMode
                            .removeFromScenario
                        }
                      </Button>
                    )}
                  </Box>
                )}
              {editMap && toggleShowLabel && (
                <Box margin={1} mt={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={toggleShowLabel}
                  >
                    {!showLabel
                      ? context.i18n[context.language].devices.showLabel
                      : context.i18n[context.language].devices.hideLabel}
                  </Button>
                </Box>
              )}
              {editMap && (
                <Box margin={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => setShowLevelSelection(true)}
                  >
                    {context.i18n[context.language].devices.moveDevice ||
                      "Move device"}
                  </Button>
                </Box>
              )}
            </DialogContent>
            <Box
              display="flex"
              flex="0 0 auto"
              padding={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <DeviceChart device={device} />
              <Button
                onClick={close}
                color="primary"
                size="large"
                style={{ zIndex: 5 }}
              >
                {context.i18n[context.language].alarms.dialog.close}
              </Button>
            </Box>
          </>
        )}
      </Box>
      <LevelSelection
        open={showLevelSelection}
        moveDevice={moveDevice}
        onClose={() => setShowLevelSelection(false)}
      />
    </Dialog>
  );

  function moveDevice(levelId: number) {
    return new Promise((resolve) => {
      let oldLevel = context.allLevels.find((l) =>
        l.devices.some((d) => d.id === device.id)
      );
      let newLevel = context.allLevels.find((l) => l.id === levelId);

      oldLevel = JSON.parse(JSON.stringify(oldLevel));
      newLevel = JSON.parse(JSON.stringify(newLevel));
      if (oldLevel && newLevel) {
        let oldObjectLayout = oldLevel.devices.find((d) => d.id === device.id);
        oldObjectLayout = JSON.parse(JSON.stringify(oldObjectLayout));

        if (oldObjectLayout) {
          newLevel.devices.push({
            ...oldObjectLayout,
            layout: {
              top: 0,
              left: 0,
              showLabel: true,
            },
          });
          oldLevel.devices = oldLevel.devices.filter((d) => d.id !== device.id);
          Axios.post("apilevels", oldLevel).then(() =>
            setTimeout(
              () =>
                Axios.post("apilevels", newLevel)
                  .then(resolve)
                  .finally(onClose),
              1000
            )
          );
        }
      }
    });
  }
};
