import * as React from "react";
import { Context, IContext } from "../../Context";
import { Container } from "@material-ui/core";
import Axios from "axios";

// Props del componente
interface Props {
}

export const Info: React.SFC<Props> = (props) => {
    const context: IContext = React.useContext(Context);
    const [info, setInfo] = React.useState("");
    React.useEffect(() => {
        //Axios.get('/apiomniVision/Info/' + context.language).then((res) => {
        Axios.get("/apiomniVision/Versione/").then((res) => {
            console.log(res);

            console.log(res.data);

            setInfo(res.data);
        });
    }, [context.language]);

    return (
        <React.Fragment>
            <Container>
                <div dangerouslySetInnerHTML={{ __html: info }} />
            </Container>
        </React.Fragment>
    );
};
