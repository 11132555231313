import {
  Box,
  Button,
  CardActions,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@material-ui/core';
import { MoreVert, StopRounded } from '@material-ui/icons';
import Axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Context, IContext } from '../../../Context';
import { Blind, blindMoveEnum, DeviceType } from '../../../Interface';
import variable from '../../../styles/variable.scss';

const MySwal = withReactContent(Swal);

interface StopBlindsProps {
  delete: Function;
}

export const StopBlinds: React.SFC<StopBlindsProps> = (props) => {
  const context: IContext = React.useContext(Context);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const stopAllBlinds = async () => {
    MySwal.fire({
      title: context.i18n[context.language].swal.stopBlinds,
      text: context.i18n[context.language].swal['areYouSure'],
      icon: 'question',
      showCancelButton: true,
      reverseButtons: true,
      customClass: `my-bg-secondary`,
      confirmButtonColor: variable[context.accent],
    }).then((result) => {
      if (result.value) {
        MySwal.fire({
          title: context.i18n[context.language].swal['blindsStopped'],
          icon: 'success',
          timer: 1500,
          customClass: `my-bg-secondary`,
          confirmButtonColor: variable[context.accent],
        });
        const blinds = context.allDevices.filter((dev) => {
          return (
            dev.type === DeviceType.blind ||
            dev.type === DeviceType.rotatingBlind
          );
        });
        blinds.forEach(async (dev) => {
          const blind = dev as Blind;
          const toSend: Blind = {
            ...blind,
            blind: {
              ...blind.blind,
              move: blindMoveEnum.STOP,
            },
          };
          console.log('Send', toSend);
          Axios.put('apidevices', toSend)
            .then((res) => {
              if (res) {
                console.log(res.status + ' ' + res.statusText);
                if (!res.data.error) {
                  console.log('Device updated.');
                }
              }
            })
            .catch((err) => {
              if (err) {
                console.log('Device not updated.');
              }
            });
        });
      }
    });
  };
  return (
    <>
      <CardContent
        style={{ flexShrink: 1, flexGrow: 1, minHeight: '0px', position: 'relative' }}
      >
        <Box
          position='absolute'
          top={theme.spacing(1)}
          right={theme.spacing(1)}
        >
          <IconButton onClick={handleClick} size='small'>
            <MoreVert />
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => props.delete()}>
            {context.i18n[context.language].widgets.general.delete}
          </MenuItem>
        </Menu>
        <Box
          height='100%'
          display='flex'
          flexDirection='column'
          justifyContent='center'
        >
          <Typography
            color='textSecondary'
            gutterBottom
            style={{ fontSize: 14 }}
          >
            {context.i18n[context.language].widgets.stopBlinds.title}
          </Typography>
          <Typography>
            {context.i18n[context.language].widgets.stopBlinds.clickToStop}
          </Typography>
        </Box>
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={stopAllBlinds}
          startIcon={<StopRounded />}
          color='primary'
        >
          {context.i18n[context.language].widgets.general.stop}
        </Button>
      </CardActions>
    </>
  );
};
