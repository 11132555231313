import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import Axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Context, IContext } from '../../Context';
import { Custom, Day, Device, Event, ScenarioInterface } from '../../Interface';
import variable from '../../styles/variable.scss';
import history from '../history';
import { TitleBar } from '../Layout/TitleBar';
import { DeviceModal } from '../NewDevices/Modals/DeviceModal';
import { DeviceList } from './DeviceList';
import { ScenarioList } from './ScenarioList';

const MySwal = withReactContent(Swal);

// Props del componente
interface ProgramsFormProps {
  program: Event;
  new: boolean;
}

// State del componente
interface ProgramsFormState {
  program: Event;
  title: string;
  description: string;
  validated: boolean;
  query: string;
  popup: boolean;
}

// Componente utilizzato per mostrare uno scenario in modalità modifica.
export class ProgramsForm extends React.Component<
  ProgramsFormProps,
  ProgramsFormState
> {
  constructor(props: ProgramsFormProps) {
    super(props);
    this.state = {
      program: props.program,
      title: '',
      description: '',
      validated: false,
      query: '',
      popup: false,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleIsRepeating = this.handleIsRepeating.bind(this);
    this.handleRepeatDay = this.handleRepeatDay.bind(this);
    this.handleStartEndDate = this.handleStartEndDate.bind(this);
    this.addNewEvent = this.addNewEvent.bind(this);
    this.modifyEvent = this.modifyEvent.bind(this);
    this.isScenarioChecked = this.isScenarioChecked.bind(this);
    this.isDeviceChecked = this.isDeviceChecked.bind(this);
    this.clickCheckBox = this.clickCheckBox.bind(this);
    this.clickRadioBox = this.clickRadioBox.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.cleanData = this.cleanData.bind(this);
    this.hideDevice = this.hideDevice.bind(this);
    this.updateDevice = this.updateDevice.bind(this);
    this.outlineRef = React.createRef();
  }

  public componentDidMount() {
    const context: IContext = this.context;
    const custom = context.custom[context.language];
    const title =
      custom.programs.name[this.state.program.id] || this.state.program.title;
    const description =
      custom.programs.description[this.state.program.id] ||
      this.state.program.description;
    this.setState({ title, description });
  }

  private outlineRef: React.RefObject<HTMLLabelElement>;
  private handleDateChange(newDate: moment.Moment | null) {
    const program = this.state.program;
    if (program && newDate) {
      this.setState({
        program: {
          ...program,
          eventDateTime: moment(newDate).seconds(0).milliseconds(0).unix(),
        },
      });
    }
  }

  private handleTypeChange(newType: string | undefined) {
    const program = this.state.program;
    if (program && newType) {
      this.setState({
        program: {
          ...program,
          type: newType,
        },
      });
    }
  }

  private handleIsRepeating() {
    const program = this.state.program;
    if (program) {
      this.setState({
        program: {
          ...program,
          repeatEvent: !!program.repeatEvent
            ? undefined
            : {
                startDate: moment().startOf('day').unix(),
                weekDays: [],
              },
        },
      });
    }
  }

  private handleRepeatDay(day: Day) {
    const program = this.state.program;
    if (program) {
      let newEvent: Day[] = [];
      const repeatedEvent = program.repeatEvent;
      if (repeatedEvent) {
        newEvent = [...repeatedEvent.weekDays];
        if (repeatedEvent.weekDays.some((a) => a === day)) {
          newEvent = newEvent.filter((a) => a !== day);
        } else {
          newEvent.push(day);
        }
      } else {
        newEvent = [day];
      }
      this.setState({
        program: {
          ...program,
          repeatEvent: {
            ...program.repeatEvent,
            weekDays: newEvent,
          },
        },
      });
    }
  }

  private isDayChecked(day: string) {
    const program = this.state.program;
    if (program && program.repeatEvent) {
      return program.repeatEvent.weekDays.some((a) => a === day);
    }
    return false;
  }

  private clickCheckBox(scenario: ScenarioInterface) {
    const program = this.state.program;
    if (program) {
      let newProgram: number[] = [];
      if (program.scenario) {
        const currentProgram = [...program.scenario];
        newProgram = currentProgram;
        if (this.isScenarioChecked(scenario.id)) {
          newProgram = currentProgram.filter((a) => a !== scenario.id);
        } else {
          newProgram.push(scenario.id);
        }
      } else {
        newProgram = [scenario.id];
      }
      this.setState({
        program: {
          ...program,
          scenario: newProgram,
        },
      });
    }
  }
  private clickRadioBox(device: Device) {
    const program = this.state.program;
    if (program) {
      this.setState({
        program: {
          ...program,
          device,
        },
      });
    }
  }

  private isScenarioChecked(id: number) {
    const program = this.state.program;

    if (program && program.scenario) {
      return program.scenario.some((a) => a === id);
    }
    return false;
  }
  private isDeviceChecked(id: number) {
    const program = this.state.program;

    if (program && program.device) {
      return program.device.id === id;
    }
    return false;
  }

  private handleStartEndDate(
    newDate: moment.Moment | null,
    startEnd: 'start' | 'end'
  ) {
    const program = this.state.program;

    const updatedDate = newDate
      ? startEnd === 'start'
        ? moment(newDate).startOf('day').unix()
        : moment(newDate).endOf('day').unix()
      : undefined;
    if (program && program.repeatEvent) {
      this.setState({
        program: {
          ...program,
          repeatEvent: {
            ...program.repeatEvent,
            startDate:
              startEnd === 'start'
                ? updatedDate
                : program.repeatEvent.startDate,
            endDate:
              startEnd === 'end' ? updatedDate : program.repeatEvent.endDate,
          },
        },
      });
    }
  }

  private async addNewEvent(program: Event) {
    const context: IContext = this.context;
    const toSend: Custom = JSON.parse(JSON.stringify(context.custom));
    if (toSend.de) {
      toSend.de.programs.name[program.id] = this.state.title;
      toSend.de.programs.description[program.id] = this.state.description;
    }
    if (toSend.en) {
      toSend.en.programs.name[program.id] = this.state.title;
      toSend.en.programs.description[program.id] = this.state.description;
    }
    if (toSend.it) {
      toSend.it.programs.name[program.id] = this.state.title;
      toSend.it.programs.description[program.id] = this.state.description;
    }
    Axios.post('apiprograms', program)
      .then((res) => {
        console.log(res.status + ' ' + res.statusText);

        if (res.data.error) {
          console.log('Error: ' + res.status + ' ' + res.data.error);
        }
      })
      .catch((err) => {
        console.log('Event created.');
        console.log(err);
      });

    Axios.post('apicustom', toSend);
  }

  private async modifyEvent(program: Event) {
    const context: IContext = this.context;
    const toSend: Custom = {
      ...context.custom,
      [context.language]: {
        ...context.custom[context.language],
        programs: {
          name: {
            ...context.custom[context.language].programs?.name,
            [program.id]: this.state.title,
          },
          description: {
            ...context.custom[context.language].programs?.description,
            [program.id]: this.state.description,
          },
        },
      },
    };

    Axios.post('apicustom', toSend);

    Axios.put('apiprograms', program)
      .then((res) => {
        console.log(res.status + ' ' + res.statusText);

        if (res.data.error) {
          console.log('Error: ' + res.status + ' ' + res.data.error);
        }
      })
      .catch((err) => {
        console.log('Event created.');
        console.log(err);
      });
  }

  private searchFilter(device: Device) {
    const context: IContext = this.context;
    const query = this.state.query.toLowerCase();
    const levelId = device.path[device.path.length - 1];
    const levelName =
      context.custom[context.language].levels.name[levelId] || '';
    return (
      device.name.toLowerCase().includes(query) ||
      levelName.toLowerCase().includes(query)
    );
  }
  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    const form = event.currentTarget;
    const program = this.state.program;
    event.preventDefault();

    const error = program.repeatEvent?.endDate
      ? (program.repeatEvent.startDate || 1) > program.repeatEvent.endDate
      : false;

    if (form.checkValidity() === false || error) {
      event.stopPropagation();
    } else if (
      program.repeatEvent &&
      program.repeatEvent.weekDays.length === 0
    ) {
      event.stopPropagation();
    } else {
      const cleanProgram = this.cleanData(program);
      console.log(this.state.program);

      if (this.props.new) {
        this.addNewEvent(cleanProgram);
      } else {
        this.modifyEvent(cleanProgram);
      }
      this.context.setLock(false).then(() => {
        history.push('/programs');
      });
    }
    this.setState({ validated: true });
  }

  private handleDelete() {
    const context: IContext = this.context;
    if (this.props.new) {
      history.push('/programs');
    } else {
      MySwal.fire({
        title: context.i18n[context.language].swal.deleteEvent,
        text: context.i18n[context.language].swal.areYouSure,
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        customClass: `my-bg-secondary`,
        confirmButtonColor: variable[context.accent],
      }).then((result) => {
        if (result.value) {
          var url: string = '/apiprograms/' + this.props.program.id;

          Axios.delete(url)
            .then((res) => {
              if (res) {
                context.setLock(false).then(async () => {
                  MySwal.fire({
                    title: context.i18n[context.language].swal.programDeleted,
                    icon: 'success',
                    timer: 1500,
                    customClass: `my-bg-secondary`,
                    confirmButtonColor: variable[context.accent],
                  });
                  history.replace('/programs');
                  this.cleanupCustom();
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }
  }

  private cleanupCustom() {
    const id = this.props.program.id;
    const context: IContext = this.context;
    const toSend: Custom = JSON.parse(JSON.stringify(context.custom));
    if (toSend.de) {
      delete toSend.de.programs.name[id];
      delete toSend.de.programs.description[id];
    }
    if (toSend.en) {
      delete toSend.en.programs.name[id];
      delete toSend.en.programs.description[id];
    }
    if (toSend.it) {
      delete toSend.it.programs.name[id];
      delete toSend.it.programs.description[id];
    }
    Axios.post('apicustom', toSend);
  }

  private handleDisabled() {
    const program = this.state.program;
    if (program) {
      this.setState({
        program: {
          ...program,
          disabled: !!program.disabled ? false : true,
        },
      });
    }
  }

  private cleanData(program: Event) {
    let cleanProgram;
    if (program.type === 'device') {
      cleanProgram = {
        ...program,
        scenario: undefined,
      };
    } else {
      cleanProgram = {
        ...program,
        device: undefined,
      };
    }
    return cleanProgram;
  }

  private hideDevice() {
    this.setState({
      popup: false,
    });
  }

  public updateDevice(device: Device) {
    const program = this.state.program;
    console.log("save", device)
    this.setState({
      program: {
        ...program,
        device,
      },
    });
  }

  private renderPopUp() {
    // const { device } = this.state.program;
    const device = this.context.allDevices.find(
      (d) => d.id === this.state.program.device?.id
    );
    if (device !== undefined) {
      return (
        <DeviceModal
          open={this.state.popup}
          onClose={this.hideDevice}
          device={device}
          onSave={this.updateDevice}
        />
      );
    }
  }
  public render() {
    const context: IContext = this.context;
    const program = this.state.program;

    if (program) {
      console.log(program);

      return (
        <>
          <TitleBar
            title={
              this.props.new
                ? context.i18n[context.language].programs.createProgram
                : context.i18n[context.language].programs.modifyProgram
            }
            onBack={history.goBack}
          />
          <Container fixed>
            <form
              noValidate
              onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                this.handleSubmit(event);
              }}
              style={{ marginBottom: '140px' }}
            >
              <div className='my-bg-secondary scenario-header'>
                <TextField
                  required
                  error={this.state.validated && this.state.title.length === 0}
                  id='program-title'
                  variant='outlined'
                  label={context.i18n[context.language].programs.name}
                  placeholder={
                    context.i18n[context.language].programs.placeholderName
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event: any) =>
                    this.setState({
                      title: event.target.value,
                    })
                  }
                  value={this.state.title}
                />
                <TextField
                  id='program-description'
                  variant='outlined'
                  label={context.i18n[context.language].programs.description}
                  placeholder={
                    context.i18n[context.language].programs
                      .placeholderDescription
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin='normal'
                  fullWidth
                  onChange={(event: any) =>
                    this.setState({
                      description: event.target.value,
                    })
                  }
                  value={this.state.description}
                />

                <DateTimePicker
                  value={program.eventDateTime * 1000}
                  ampm={context.language === 'en'}
                  format='LLLL'
                  onChange={this.handleDateChange}
                  label={context.i18n[context.language].programs.date}
                  inputVariant='outlined'
                  margin='normal'
                  fullWidth
                />
                <br />
                <FormControl
                  variant='outlined'
                  style={{
                    marginTop: '20px',
                    width: '100%',
                  }}
                >
                  <InputLabel
                    ref={this.outlineRef}
                    htmlFor='outlined-age-simple'
                  >
                    {context.i18n[context.language].programs.programType}
                  </InputLabel>
                  <Select
                    value={program.type}
                    onChange={(event: any) => {
                      this.handleTypeChange(event.target.value);
                    }}
                    input={
                      <OutlinedInput
                        labelWidth={
                          this.outlineRef.current
                            ? this.outlineRef.current.offsetWidth
                            : 0
                        }
                        name='age'
                        id='filled-age-simple'
                      />
                    }
                  >
                    <MenuItem value={'device'}>
                      {context.i18n[context.language].programs.device}
                    </MenuItem>
                    <MenuItem value={'scenario'}>
                      {context.i18n[context.language].programs.scenarios}
                    </MenuItem>
                  </Select>
                </FormControl>

                {program.type === 'device' && (
                  <>
                    <div
                      style={{
                        margin: '5px',
                        marginTop: '15px',
                      }}
                    >
                      <h4>
                        {context.i18n[context.language].programs.selectDevice}
                      </h4>
                    </div>
                    <TextField
                      fullWidth
                      label={context.i18n[context.language].programs.search}
                      onChange={(event: any) =>
                        this.setState({
                          query: event.target.value,
                        })
                      }
                      value={this.state.query}
                    />
                    <div
                      style={{
                        margin: '20px 0',
                        backgroundColor: 'rgba(0,0,0,0.03)',
                        height: '300px',
                        overflowY: 'scroll',
                        borderRadius: '5px',
                      }}
                    >
                      <DeviceList
                        onClick={this.clickRadioBox}
                        searchFilter={this.searchFilter}
                        isDeviceChecked={this.isDeviceChecked}
                        query={this.state.query}
                      />
                    </div>
                  </>
                )}
                {program.type === 'scenario' && (
                  <>
                    <div
                      style={{
                        margin: '5px',
                        marginTop: '15px',
                      }}
                    >
                      <h4>
                        {
                          context.i18n[context.language].programs
                            .selectScenarios
                        }
                      </h4>
                    </div>
                    <div
                      style={{
                        margin: '20px 0',
                        backgroundColor: 'rgba(0,0,0,0.03)',
                        height: '300px',
                        overflowY: 'scroll',
                        borderRadius: '5px',
                      }}
                    >
                      <ScenarioList
                        clickCheckBox={this.clickCheckBox}
                        isScenarioChecked={this.isScenarioChecked}
                      />
                    </div>
                  </>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!program.disabled}
                      onChange={this.handleDisabled}
                      color='primary'
                    />
                  }
                  label={context.i18n[context.language].programs.isDisabled}
                />
                {this.state.program.type === 'device' &&
                  this.state.program.device && (
                    <Button
                      onClick={() => this.setState({ popup: true })}
                      color='primary'
                      variant='outlined'
                      style={{ float: 'right' }}
                    >
                      {context.i18n[context.language].programs.editDevice}
                    </Button>
                  )}
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!program.repeatEvent}
                        onChange={this.handleIsRepeating}
                        color='primary'
                      />
                    }
                    label={context.i18n[context.language].programs.isRepeating}
                  />
                  <br />

                  {!!program.repeatEvent && (
                    <>
                      <FormControl
                        required
                        error={program.repeatEvent.weekDays.length === 0}
                        component='fieldset'
                      >
                        <FormLabel component='legend'>
                          {
                            context.i18n[context.language].programs
                              .pickAtLeastOne
                          }
                        </FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.isDayChecked('mon')}
                                onChange={() => this.handleRepeatDay('mon')}
                                color='primary'
                              />
                            }
                            label={context.i18n[context.language].programs.mon}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.isDayChecked('tue')}
                                onChange={() => this.handleRepeatDay('tue')}
                                color='primary'
                              />
                            }
                            label={context.i18n[context.language].programs.tue}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.isDayChecked('wed')}
                                onChange={() => this.handleRepeatDay('wed')}
                                color='primary'
                              />
                            }
                            label={context.i18n[context.language].programs.wed}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.isDayChecked('thu')}
                                onChange={() => this.handleRepeatDay('thu')}
                                color='primary'
                              />
                            }
                            label={context.i18n[context.language].programs.thu}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.isDayChecked('fri')}
                                onChange={() => this.handleRepeatDay('fri')}
                                color='primary'
                              />
                            }
                            label={context.i18n[context.language].programs.fri}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.isDayChecked('sat')}
                                onChange={() => this.handleRepeatDay('sat')}
                                color='primary'
                              />
                            }
                            label={context.i18n[context.language].programs.sat}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.isDayChecked('sun')}
                                onChange={() => this.handleRepeatDay('sun')}
                                color='primary'
                              />
                            }
                            label={context.i18n[context.language].programs.sun}
                          />
                        </FormGroup>
                      </FormControl>
                      <br />
                      <DatePicker
                        label={
                          context.i18n[context.language].programs.startDate
                        }
                        value={
                          program.repeatEvent.startDate
                            ? program.repeatEvent.startDate * 1000
                            : null
                        }
                        format='LL'
                        onChange={(newDate) =>
                          this.handleStartEndDate(newDate, 'start')
                        }
                        clearable
                        className='mr-3'
                      />
                      <DatePicker
                        label={context.i18n[context.language].programs.endDate}
                        value={
                          program.repeatEvent.endDate
                            ? program.repeatEvent.endDate * 1000
                            : null
                        }
                        format='LL'
                        onChange={(newDate) => {
                          this.handleStartEndDate(newDate, 'end');
                          console.log(program.repeatEvent?.startDate);
                        }}
                        clearable
                        error={
                          program.repeatEvent.endDate
                            ? (program.repeatEvent.startDate || 1) >
                              program.repeatEvent.endDate
                            : false
                        }
                        minDate={program.repeatEvent.startDate}
                      />
                    </>
                  )}
                </div>
              </div>
              <Button
                variant='contained'
                type='submit'
                color='primary'
                className='my-3 float-right'
              >
                {context.i18n[context.language].programs.save}
              </Button>
              <Button
                variant='outlined'
                className='m-3 float-right'
                onClick={this.handleDelete}
              >
                {this.props.new
                  ? context.i18n[context.language].scenarios.cancel
                  : context.i18n[context.language].programs.delete}
              </Button>
            </form>
            {this.renderPopUp()}
          </Container>
        </>
      );
    }
  }
}

ProgramsForm.contextType = Context;
