import {
    Box,
    Fab,
    makeStyles,
    Slider,
    Typography,
    useTheme,
} from "@material-ui/core";
import { blue, orange } from "@material-ui/core/colors";
import { Add, Remove, Flare } from "@material-ui/icons";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Device, DimLight, statusEnum } from "../../../../Interface";
import { ToggleButton } from "./../ToggleButton";

interface Props {
    device: DimLight;
    updateDevice: (device: Device) => void;
}

export const useStyles = makeStyles((theme) => ({
    track: {
        width: "20px !important",
        borderRadius: "8px",
    },
    thumb: {
        opacity: 0,
    },
    rail: {
        width: "20px !important",
        borderRadius: "8px",
        opacity: 1,
        backgroundColor: theme.palette.background.default,
        boxShadow: theme.shadows[2],
    },
}));

export const DimLightControl: FunctionComponent<Props> = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { device, updateDevice } = props;
    const status = device.light.status ? "ON" : "OFF";
    const [active, setActive] = useState(false)
    let timeout: NodeJS.Timeout | undefined;
    const changeStatus = (value: string) => {
        const newDevice: Device = {
            ...device,
            light: {
                ...device.light,
                status: value === "ON" ? 1 : 0,
            },
        };
        updateDevice(newDevice);
        console.log("Sent light dim cmd to core(change state): " + JSON.stringify(newDevice));
    };


    const { min, max, value } = device.light.dim;
    const [stateValue, setStateValue] = React.useState(value);

    const changeDim = (value: number) => {
        value = Math.max(0, Math.min(100, Number(value)));
        if (timeout) {
            clearTimeout(timeout)
        }
        setActive(true)
        const newDevice: Device = {
            ...device,
            light: {
                ...device.light,
                dim: {
                    ...device.light.dim,
                    value,
                },
                status: value === 0 ? statusEnum.OFF : statusEnum.ON,
            },
        };
        setStateValue(value);
        updateDevice(newDevice);
        console.log("Sent light dim cmd to core (change dim): " + JSON.stringify(newDevice));
        timeout = setTimeout(() => setActive(false), 5000)
    };

    const kelvin = device.light.kelvin;
    const [stateValueKelvin, setStateValueKelvin] = useState(kelvin?.value);

    const changeKelvin = (value: number) => {
        value = Math.max(0, Math.min(10000, Number(value)));
        if (!device.light.kelvin) return;
        if (timeout) {
            clearTimeout(timeout)
        }
        setActive(true)
        const newDevice: Device = {
            ...device,
            light: {
                ...device.light,
                kelvin: {
                    ...device.light.kelvin,
                    value,
                },
                status: value === 0 ? statusEnum.OFF : statusEnum.ON,
            },
        };
        setStateValueKelvin(value);
        updateDevice(newDevice);
        console.log("Sent light dim cmd to core (change kelvin): " + JSON.stringify(newDevice));
        timeout = setTimeout(() => setActive(false), 5000)
    };

    useEffect(() => {
        if (!active) {
            setStateValue(value);
        }
    }, [device.light.dim.value, device.light.status, active]);

    useEffect(() => {
        if (!active) {
            setStateValueKelvin(kelvin?.value);
        }
    }, [device.light.kelvin?.value, device.light.status, active]);

    return (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
        >
            <Box display="flex" justifyContent="center" padding={3}>
                <Box width="150px">
                    <ToggleButton
                        value={status}
                        onChange={changeStatus}
                        labels={["ON", "OFF"]}
                    />
                </Box>

                {kelvin &&
                    <Box display="flex" justifyContent="center">
                        <Box paddingLeft={2}>
                            <Slider
                                orientation="vertical"
                                min={kelvin?.min}
                                max={kelvin?.max}
                                classes={classes}
                                valueLabelDisplay="on"
                                value={stateValueKelvin}
                                onChange={(_e, v) => setStateValueKelvin(v as number)}
                                onChangeCommitted={(_e, v) => changeKelvin(v as number)}
                            />
                            <Typography style={{ width: "50px", textAlign: "center" }}>
                                {stateValueKelvin}K
                            </Typography>
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                        >
                            <Flare fontSize="large" style={{ color: blue[500] }} />
                            <Flare fontSize="large" style={{ color: orange[500] }} />
                        </Box>
                    </Box>
                }

                <Box paddingLeft={2}>
                    <Slider
                        orientation="vertical"
                        min={min}
                        max={max}
                        classes={classes}
                        valueLabelDisplay="on"
                        value={stateValue}
                        onChange={(_e, v) => setStateValue(v as number)}
                        onChangeCommitted={(_e, v) => changeDim(v as number)}
                    />
                    <Typography style={{ width: "50px", textAlign: "center" }}>
                        {stateValue}%
                    </Typography>
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                >
                    <Fab
                        size="small"
                        color="primary"
                        onClick={() => changeDim(stateValue + 1)}
                    >
                        <Add />
                    </Fab>
                    <Fab
                        size="small"
                        color="primary"
                        onClick={() => changeDim(stateValue - 1)}
                    >
                        <Remove />
                    </Fab>
                </Box>

            </Box>
        </Box >
    );
};