import FullCalendar, { EventSourceInput } from "@fullcalendar/react";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import { withTheme } from "@material-ui/core/styles";
import { CalendarContextProvider } from "../../CalendarContext";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Theme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import * as React from "react";
import { Redirect } from "react-router";
import { Day, Event } from "../../Interface";
import history from "../history";
import { TitleBar } from "../Layout/TitleBar";
import CustomListView from "./ListView";
import { ProgramsForm } from "./ProgramsForm";
import { IContext, ContextProvider, Context } from "../../Context";

// Props del componente
interface ProgramsContainerProps {
  match: {
    params: {
      id: string;
    };
  };
}

// State del componente
interface ProgramsContainerState {
  event?: Event;
  dialog: boolean;
  width: number;
  height: number;
}

// Pagina da fare.
// Componente che contiene il componente che renderizza la pagina dei programmi.
class ProgramsContainerRaw extends React.Component<
  ProgramsContainerProps & { theme: Theme },
  ProgramsContainerState
> {
  private calendar: React.RefObject<FullCalendar> = React.createRef();
  constructor(props: ProgramsContainerProps & { theme: Theme }) {
    super(props);
    this.state = {
      dialog: false,
      width: 0,
      height: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.setCalendarView = this.setCalendarView.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState(
      {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      this.setCalendarView
    );
    this.calendar.current?.render();
  }

  private setCalendarView() {
    if (this.calendar.current && this.state.width < 768) {
      if (
        this.calendar.current.getApi().view.type !== "listWeek" &&
        this.calendar.current.getApi().view.type !== "listView"
      ) {
        this.calendar.current.getApi().changeView("listWeek");
      }
    }
  }

  private newEvent(): Event {
    const context: IContext = this.context;
    return {
      id: Math.max(0, ...context.allPrograms.map((a) => a.id)) + 1,
      title: "",
      description: "",
      eventDateTime: moment().unix(),
      type: "scenario",
    };
  }

  private getEvents(): EventSourceInput {
    const context: IContext = this.context;

    const dayMap = {
      sun: 0,
      mon: 1,
      tue: 2,
      wed: 3,
      thu: 4,
      fri: 5,
      sat: 6,
    };
    return context.allPrograms
      .filter((p) => !p.disabled)
      .map((event) => {
        const DOW = !!event.repeatEvent
          ? event.repeatEvent.weekDays.map((day) => dayMap[day])
          : undefined;

        const date = moment(event.eventDateTime * 1000);

        return {
          id: event.id.toString(),
          title:
            context.custom[context.language].programs.name[event.id] ||
            event.title,
          allDay: false,
          daysOfWeek: DOW,
          startTime: !!event.repeatEvent ? date.format("HH:mm") : undefined,
          start: !!event.repeatEvent ? undefined : date.format(),
          end: !!event.repeatEvent
            ? undefined
            : date.hours() >= 23
            ? date.hours(23).minutes(59).format()
            : undefined,
          endTime: !!event.repeatEvent
            ? date.hours() >= 23
              ? "23:59"
              : undefined
            : undefined,
          startRecur:
            event.repeatEvent && event.repeatEvent.startDate
              ? moment(event.repeatEvent.startDate * 1000).format()
              : undefined,
          endRecur:
            event.repeatEvent && event.repeatEvent.endDate
              ? moment(event.repeatEvent.endDate * 1000).format()
              : undefined,
        };
      });
  }

  public render() {
    const context: IContext = this.context;
    let id = this.props.match.params.id;
    this.setCalendarView();
    if (id) {
      if (id === "new") {
        const event = this.newEvent();
        return <ProgramsForm program={event} new={true} />;
      } else {
        const event = context.allPrograms.find(
          (a) => a.id === Number.parseInt(id)
        );

        if (event) {
          return <ProgramsForm program={event} new={false} />;
        } else {
          return <Redirect to={"/programs"} />;
        }
      }
    } else {
      return (
        <CalendarContextProvider
          value={{
            calendar: this.calendar,
            dialog: this.state.dialog,
            event: this.state.event,
            setEvent: (event: Event) => this.setState({ event }),
            setDialog: (dialog: boolean) => this.setState({ dialog }),
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "100vh",
              height: "100%",
            }}
          >
            <TitleBar title={context.i18n[context.language].programs.title} />
            <div className="calendar-container">
              <FullCalendar
                ref={this.calendar}
                height="auto"
                firstDay={1}
                allDaySlot={false}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right:
                    this.state.width > 768
                      ? "timeGridDay,timeGridWeek,dayGridMonth,listWeek,listView"
                      : "listWeek,listView",
                }}
                buttonText={{
                  listView: context.i18n[context.language].programs.programs,
                }}
                locales={allLocales}
                locale={context.language}
                initialView="dayGridMonth"
                rerenderDelay={2000}
                plugins={[
                  dayGridPlugin,
                  listPlugin,
                  bootstrapPlugin,
                  timeGridPlugin,
                  CustomListView,
                ]}
                events={this.getEvents()}
                eventColor={this.props.theme.palette.primary.main}
                eventTextColor="#fff"
                eventClick={(args) => {
                  this.setState({
                    event: context.allPrograms.find(
                      (a) => a.id.toString() === args.event.id
                    ),
                    dialog: true,
                  });
                }}
              />
            </div>
            <Fab
              onClick={() => {
                context.setLock(false).then(() => {
                  history.push("programs/new");
                  context.setLock(true);
                });
              }}
              color="primary"
              className="fab-fixed"
            >
              <AddIcon />
            </Fab>
            <Dialog
              open={this.state.dialog}
              onClose={() => {
                this.setState({
                  dialog: false,
                });
              }}
              maxWidth="xs"
              fullWidth
            >
              <DialogTitle>
                {this.state.event &&
                  (context.custom[context.language].programs.name[
                    this.state.event.id
                  ] ||
                    this.state.event.title)}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {this.state.event &&
                    (context.custom[context.language].programs.description[
                      this.state.event.id
                    ] ||
                      this.state.event.description)}
                </DialogContentText>
                <DialogContentText>
                  {this.state.event &&
                    moment(this.state.event.eventDateTime * 1000).format(
                      "LLLL"
                    )}
                </DialogContentText>
                {this.state.event &&
                  this.state.event.repeatEvent &&
                  moment.weekdaysShort(true).map((day) => {
                    const weekDays = this.state.event!.repeatEvent!.weekDays;
                    const weekDay: Day = moment()
                      .day(day)
                      .locale("en")
                      .format("ddd")
                      .toLowerCase() as any;

                    const color = weekDays.includes(weekDay)
                      ? "primary"
                      : undefined;
                    return (
                      <Chip
                        key={`week-${day}`}
                        label={day}
                        color={color}
                        className="m-1"
                      />
                    );
                  })}
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() => {
                    this.setState({
                      dialog: false,
                    });
                    const event = this.state.event;
                    if (event) {
                      context.setLock(false).then(() => {
                        history.push(`/programs/${event.id}`);
                        context.setLock(true);
                      });
                    }
                  }}
                >
                  {context.i18n[context.language].programs.edit}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </CalendarContextProvider>
      );
    }
  }
}

ProgramsContainerRaw.contextType = Context;
export const ProgramsContainer = withTheme(ProgramsContainerRaw);
