import * as React from 'react';
import { MapImage, ScenarioInterface, SubLevel } from '../../Interface';

export interface IMapContext {
  setFavorite: (path: number[] | undefined) => void;
  favoritePath?: number[];
  toScenario: (scenario: ScenarioInterface) => void;
  textMode: boolean;
  path: number[];
  setSwipe: (swipe: boolean) => void;
  scenario?: ScenarioInterface;
  setScenario: (scenario: ScenarioInterface) => void;
}

const mapContext = React.createContext<IMapContext>({
  setFavorite: (path: number[] | undefined) => {},
  toScenario: (scenario: ScenarioInterface) => {},
  textMode: false,
  path: [],
  setSwipe: (swipe: boolean) => {},
  setScenario: (scenario: ScenarioInterface) => {},
});

export const MapContextProvider = mapContext.Provider;
export const MapContextConsumer = mapContext.Consumer;
export const MapContext = mapContext;
