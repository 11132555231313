import { createPlugin, ViewProps } from "@fullcalendar/react";
import {
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  useTheme,
} from "@material-ui/core";
import moment from "moment";
import React, { useContext } from "react";
import { CalendarContext } from "../../CalendarContext";
import { Context } from "../../Context";

const CustomView: React.FunctionComponent<ViewProps> = (props) => {
  const theme = useTheme();
  const calendarContext = useContext(CalendarContext);
  const context = useContext(Context);
  return (
    <List
      style={{
        padding: 0,
      }}
    >
      {context.allPrograms.map((program, index) => {
        const custom = context.custom[context.language];
        const title = custom.programs.name[program.id] || program.title;
        return (
          <ListItem
            key={"event-" + program.id}
            button
            className={index % 2 === 0 ? "events-list-view-item" : ""}
            onClick={() => {
              calendarContext.setEvent(program);
              calendarContext.setDialog(true);
            }}
          >
            <ListItemIcon>
              <i
                className="fc-list-event-dot ml-2"
                style={{
                  borderColor: program.disabled
                    ? theme.palette.grey[500]
                    : theme.palette.primary.main,
                }}
              />
            </ListItemIcon>
            <ListItemText primary={title} />
            {program.repeatEvent && (
              <ListItemSecondaryAction className="repeating-day-list-view">
                {moment.weekdaysShort(true).map((day) => {
                  const weekDays = program.repeatEvent?.weekDays.map((d) =>
                    moment().locale("en").day(d).format("ddd").toLowerCase()
                  );
                  const weekDay: string = moment()
                    .day(day)
                    .locale("en")
                    .format("ddd")
                    .toLowerCase();
                  const color = weekDays?.includes(weekDay)
                    ? "primary"
                    : undefined;

                  return (
                    <Chip
                      key={`week-${day}`}
                      label={day}
                      color={color}
                      className="m-1"
                    />
                  );
                })}
              </ListItemSecondaryAction>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

export default createPlugin({
  views: {
    listView: CustomView,
  },
});
