import { Box, Typography, useTheme } from "@material-ui/core";
import React, { PropsWithChildren } from "react";
import {
  Blind,
  blindMoveEnum,
  Device,
  DeviceType,
  DimLight,
  Sensor,
  Thermostat,
} from "../../../Interface";

interface PillBadgeProps {
  device: Device;
}
export const PillBadge = (props: PillBadgeProps) => {
  const { device } = props;
  const theme = useTheme();
  if (
    (device.type !== DeviceType.dimLight &&
      device.type !== DeviceType.thermostat &&
      device.type !== DeviceType.blind &&
      device.type !== DeviceType.rotatingBlind &&
      device.type !== DeviceType.sensor) ||
    (device as Sensor).sensor?.sensorValue === undefined
  )
    return <></>;
  return (
    <Box
      height='24px'
      minWidth='24px'
      display='flex'
      alignItems='center'
      justifyContent='center'
      position='absolute'
      top='-12px'
      right='-12px'
      borderRadius='12px'
      padding='5px'
      bgcolor={theme.palette.primary.main}
      color={theme.palette.getContrastText(theme.palette.primary.main)}
      boxShadow={3}
    >
      <Typography variant='overline' noWrap>
        {getPillValue()}
      </Typography>
    </Box>
  );

  function getPillValue() {
    switch (device.type) {
      case DeviceType.dimLight:
        return (device as DimLight).light.dim.value + "%";
      case DeviceType.thermostat:
        return (device as Thermostat).thermostat.temperature + "°C";
      case DeviceType.rotatingBlind:
      case DeviceType.blind:
        const blind = (device as Blind).blind;
        switch (blind.move) {
          case blindMoveEnum.UP:
            return <i className='fas fa-chevron-circle-up' />;
          case blindMoveEnum.DOWN:
            return <i className='fas fa-chevron-circle-down' />;
          default:
            return blind.position.value + "%";
        }
      case DeviceType.sensor:
        const sensor = (device as Sensor).sensor;
        if (sensor.sensorTxtConvert) {
          return sensor.sensorTxtConvert.find(
            (e) => e.val === sensor.sensorValue
          )?.txt;
        } else {
          return `${sensor.sensorValue} ${
            sensor.sensorUdm !== undefined && sensor.sensorUdm
          }`;
        }

      default:
        return "";
    }
  }
};
