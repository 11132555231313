import {
  Box,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import * as React from "react";
import { Context, IContext } from "../../../Context";
import { occupancyEnum, thermoModeEnum, Thermostat } from "../../../Interface";
import { DeviceModal } from "../../NewDevices/Modals/DeviceModal";

interface ThermoWidgetProps {
  device: Thermostat;
  sendUpdate: Function;
  delete: Function;
  unsetDevice: Function;
}
export const ThermoWidgetComponent: React.SFC<ThermoWidgetProps> = (props) => {
  const context: IContext = React.useContext(Context);
  const theme = useTheme();

  const [showPopup, setPopup] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => props.delete()}>
          {context.i18n[context.language].widgets.general.delete}
        </MenuItem>
        <MenuItem onClick={() => props.unsetDevice()}>
          {context.i18n[context.language].widgets.menu.changeDevice}
        </MenuItem>
      </Menu>
      <CardContent
        style={{
          flexShrink: 1,
          minHeight: 0,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexGrow: 1,
        }}
      >
        <Box
          position="absolute"
          top={theme.spacing(1)}
          right={theme.spacing(1)}
        >
          <IconButton onClick={handleClick} size="small">
            <MoreVert />
          </IconButton>
        </Box>
        <Typography color="textSecondary" gutterBottom style={{ fontSize: 14 }}>
          {context.custom[context.language].devices.name[props.device.id]}
        </Typography>
        <div style={{ cursor: "pointer" }} onClick={() => setPopup(true)}>
          <div className="thermo-widget-body">
            <div className="thermo-widget-temperature">
              {Number(props.device.thermostat.temperature).toFixed(1)}
              ˚C
              <br />
              <span className="thermo-widget-setpoint">
                <i
                                  className={`fas fa-crosshairs light-icon`}
                />{" "}
                {Number(props.device.thermostat.setpoint.value).toFixed(1)}{" ˚C"}
                 
              </span>
            </div>
            <div className="thermo-widget-info">
              <div>
                    {/*{props.device.thermostat.humidity && `${props.device.thermostat.humidity} %`}*/}
                    <span className="float-left">
                       {occupancyEnum[props.device.thermostat.occupancy]}
                    </span>
              </div>
              <br/>
              <div className="text-align-left">
                <i
                    className={`far fa-snowflake ${props.device.thermostat.thermoMode !== thermoModeEnum.COOL
                            ? "off-icon"
                            : ""
                        }`}
                />{" "}
                <i
                    className={`far fa-sun ${props.device.thermostat.thermoMode !== thermoModeEnum.HEAT
                            ? "off-icon"
                            : ""
                        }`}
                />{" "}
                {props.device.thermostat.windowAlarm && (
                  <i
                    className={`fas fa-table ${
                      props.device.thermostat.windowAlarm === true
                        ? "color-accent-red"
                        : "off-icon"
                    }`}
                  />
                )}{" "}
                {props.device.thermostat.condensationAlarm && (
                  <i
                    className={`fas fa-tint ${
                      props.device.thermostat.condensationAlarm === true
                        ? "color-accent-red"
                        : "off-icon"
                    }`}
                  />
                )}
              </div>
              {props.device.thermostat.fanSpeed && (
                <div>
                  <i className="fas fa-wind" />{" "}
                  {props.device.thermostat.fanSpeed.value >= 0
                    ? props.device.thermostat.fanSpeed.value + " %"
                    : "Auto"}
                </div>
              )}
            </div>
          </div>
        </div>
      </CardContent>
      <DeviceModal
        device={props.device}
        open={showPopup}
        onClose={() => setPopup(false)}
        locked={props.device.readOnly}
      />
    </>
  );
};
