import { Box, Button, useTheme } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import Ratio from "react-ratio";

interface Props {
  value: string;
  onChange: (value: string) => void;
  labels: [string, string];
  horizontal?: boolean;
}

export const ToggleButton: FunctionComponent<Props> = (props) => {
  const { value, onChange, labels, horizontal } = props;
  const theme = useTheme();

  return (
    <Ratio ratio={horizontal ? 2 / 1 : 1 / 2}>
      <Box
        height="100%"
        width="100%"
        bgcolor={theme.palette.background.default}
        borderRadius={10}
        boxShadow={theme.shadows[2]}
        display="flex"
        flexDirection={horizontal ? "row-reverse" : "column"}
        alignItems="stretch"
      >
        <Button
          variant={value === labels[0] ? "contained" : "text"}
          color={value === labels[0] ? "primary" : "default"}
          onClick={() => onChange("ON")}
          style={{
            flex: 1,
            margin: "5%",
            padding: 0,
          }}
        >
          {labels[0]}
        </Button>
        <Button
          variant={value === labels[1] ? "contained" : "text"}
          color={value === labels[1] ? "primary" : "default"}
          onClick={() => onChange("OFF")}
          style={{
            flex: 1,
            margin: "5%",
            marginTop: horizontal ? "5%" : 0,
            marginRight: horizontal ? 0 : "5%",
            padding: 0,
          }}
        >
          {labels[1]}
        </Button>
      </Box>
    </Ratio>
  );
};
