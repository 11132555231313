import {
  Box,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import * as React from "react";
import { useContext, useState } from "react";
import { Context, IContext } from "../../../Context";
import { Blind, RotatingBlind, statusEnum } from "../../../Interface";
import variable from "../../../styles/variable.scss";
import { DeviceIcon } from "../../Devices/DeviceIcon";
import { DeviceModal } from "../../NewDevices/Modals/DeviceModal";

interface BlindWidgetProps {
  device: Blind | RotatingBlind;
  sendUpdate: Function;
  delete: Function;
  unsetDevice: Function;
}
export const BlindWidgetComponent: React.FunctionComponent<BlindWidgetProps> = (
  props
) => {
  const context: IContext = useContext(Context);
  const theme = useTheme();
  const [showPopup, setPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let style: React.CSSProperties | undefined;
  const accentColor = variable[context.accent];
  const perc = Math.round(props.device.blind.position.value / 25) * 25;
  const direction =
    JSON.stringify(props.device.icons) === JSON.stringify([81])
      ? "left"
      : "top";
  style = {
    background: `-webkit-linear-gradient(
                                    ${direction},
                                    ${accentColor} 0%,
                                    ${accentColor} ${perc}%,
                                    #606060 ${perc}%,
                                    #606060 100%
                                )`,
  };
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => props.delete()}>
          {context.i18n[context.language].widgets.general.delete}
        </MenuItem>
        <MenuItem onClick={() => props.unsetDevice()}>
          {context.i18n[context.language].widgets.menu.changeDevice}
        </MenuItem>
      </Menu>
      <CardContent
        style={{
          flexShrink: 1,
          minHeight: 0,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: 1,
          paddingBottom: 0,
        }}
      >
        <Box
          position="absolute"
          top={theme.spacing(1)}
          right={theme.spacing(1)}
        >
          <IconButton onClick={handleClick} size="small">
            <MoreVert />
          </IconButton>
        </Box>
        <Typography color="textSecondary" gutterBottom style={{ fontSize: 14 }}>
          {context.custom[context.language].devices.name[props.device.id]}
        </Typography>
        <div className="light-widget-container" onClick={() => setPopup(true)}>
          <div className={"light-widget-icon color-accent"}>
            <DeviceIcon
              default={"fas fa-align-justify"}
              status={statusEnum.OFF}
              icons={props.device.icons}
              style={style}
              className={"half-icon fa-3x"}
            />
          </div>
        </div>
      </CardContent>
      <DeviceModal
        device={props.device}
        open={showPopup}
        onClose={() => setPopup(false)}
        locked={props.device.readOnly}
      />
    </>
  );
};
