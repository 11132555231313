import { Drawer, Hidden, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import { useContext, useState } from "react";
import { Prompt, Redirect, Route, Switch } from "react-router";
import { Context } from "../../Context";
import {
  Alarm,
  Device,
  Error,
  Event,
  HomeConfig,
  MapImage,
  ScenarioInterface,
  SubLevel,
} from "../../Interface";
import { AlarmContainer } from "../Alarms/AlarmContainer";
import history from "../history";
import { Home } from "../Home/Home";
import { Widget } from "../Home/Widgets/Widgets";
import { Devices } from "../NewDevices/Devices";
import { ProgramsContainer } from "../Programs/ProgramsContainer";
import { ScenarioContainer } from "../Scenario/ScenarioContainer";
import { SettingsContainer } from "../Settings/SettingsContainer";
import { WeatherContainer } from "../Weather/WeatherContainer";
import { SidebarContent } from "./SidebarContent";
// import Route from '../api-authorization/Route';

// Props del componente provenienti da Layout.tsx
interface DisplayProps {
  allDevices: Device[]; // Array di dispositivi che che viene mandato dal backend
  allLevels: SubLevel[]; // Array di livelli che che viene mandato dal backend
  start: number; // Array con l'id dei device da visualizzare all'apertura del DevicesContainer.tsx
  allScenarios: Array<ScenarioInterface>; // Array di scenario che che viene mandato dal backend
  alarms: Alarm[]; // Array di allarmi che che viene mandato dal backend
  homeConfig: HomeConfig; // File di configurazione della pagina home
  connectionErrors: Array<Error>; // Array di errori di connessione
  addconnectionError: Function; // Funzione per aggiungere una connectionerror
  removeConnectionError: Function; // Funzione per rimuovere una connectionerror
  removeAllConnectionErrors: Function; // Funzione per rimuovere tutte le connectionError
  programs: Array<Event>; // Array di programmi
  widgets: Widget[];
  setWidgets: (newWidgets: Widget[]) => Promise<unknown>;
}

// State del componente
const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    overflowY: "auto",
    zIndex: 1000,
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerClose: {
    width: theme.spacing(9),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexBasis: 0,
    flexGrow: 1,
    overflowX: "hidden",
  },
}));
// Componente principale che si occupa della renderizzazione delle diverse pagine nell'applicazione.
// È il componente principale, che mostra banner e la pagina in cui l'utente si trova.
export const Display: React.FunctionComponent<DisplayProps> = (props) => {
  const {
    start,
    widgets,
    allDevices,
    allLevels,
    allScenarios,
    alarms,
    setWidgets,
    homeConfig,
  } = props;
  const compact = localStorage.getItem("compact") === "true" || false;
  const textMode =
    props.homeConfig.textMode || localStorage.getItem("textMode") === "true";
  const classes = useStyles();
  const storageFavorite = localStorage.getItem("favoriteLevels");
  const context = useContext(Context);
  const [docked, setDocked] = useState(true);
  const [pageLocked, setPageLocked] = useState(false);
  const [compactSidebar, setCompactSidebar] = useState(compact);
  const [favoriteLevels, setFavoriteLevels] = useState<number[] | undefined>(
    storageFavorite !== null ? JSON.parse(storageFavorite) : undefined
  );

  React.useEffect(() => {
    updateWindowDimension();
    window.addEventListener("resize", updateWindowDimension);

    return () => {
      window.removeEventListener("resize", updateWindowDimension);
    };
  }, []);

  return (
    <div className={classes.root}>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={"left"}
            open={context.isSidebarOpen}
            onClose={() => context.setIsSidebarOpen(false)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <SidebarContent
              homeConfig={homeConfig}
              setCompact={setCompact}
              compactSidebar={compactSidebar}
              toggleSidebar={toggleSidebar}
            />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            variant="permanent"
            open
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: !compactSidebar,
              [classes.drawerClose]: compactSidebar,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: !compactSidebar,
                [classes.drawerClose]: compactSidebar,
              }),
            }}
          >
            <SidebarContent
              homeConfig={homeConfig}
              setCompact={setCompact}
              compactSidebar={compactSidebar}
              toggleSidebar={toggleSidebar}
            />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <Prompt
          when={context.lock}
          message={(location) => {
            if (
              location.pathname.split("/")[1] === "devices" &&
              history.location.pathname.split("/")[1] === "devices"
            ) {
              return true;
            } else {
              return context.i18n[context.language].swal.areYouSure;
            }
          }}
        />
        <Switch>
          <Route exact path="/home">
            <Home widgets={widgets} setWidgets={setWidgets} />
          </Route>
          <Route
            exact
            path="/devices/:id*"
            render={({ match }: any) => (
              <>
                {match.params.id && (
                  <Devices
                    textMode={textMode}
                    setFavorite={setFavorite}
                    favoritePath={favoriteLevels}
                    match={match}
                    toScenario={devicesToScenarios}
                  />
                )}
                {!match.params.id && (
                  <Redirect
                    to={`/devices/${
                      favoriteLevels ? favoriteLevels.join("/") : start
                    }`}
                  />
                )}
              </>
            )}
          />
          <Route
            exact
            path="/scenarios/:id?"
            render={({ match }: any) => (
              <ScenarioContainer
                scenarios={allScenarios}
                toDevices={scenariosToDevices}
                allDevices={allDevices}
                allLevels={allLevels}
                match={match}
              />
            )}
          />
          <Route
            exact
            path="/programs/:id?"
            render={({ match }: any) => <ProgramsContainer match={match} />}
          />
          <Route
            exact
            path="/alarms"
            render={() => <AlarmContainer alarms={alarms} />}
          />
          <Route
            exact
            path="/weather"
            render={() => <WeatherContainer allDevices={allDevices} />}
          />
          <Route
            exact
            path="/settings"
            render={() => (
              <SettingsContainer config={homeConfig} page={"menu"} />
            )}
          />
          <Route
            exact
            path="/settings/:page"
            render={({ match }: any) => (
              <SettingsContainer config={homeConfig} page={match.params.page} />
            )}
          />
          <Route>
            <Redirect to="/home" />
          </Route>
        </Switch>
      </main>
    </div>
  );

  // Funzione chiamata per aprire/chiudere la sidebar.
  function toggleSidebar() {
    if (docked) {
      context.setIsSidebarOpen(!context.isSidebarOpen);
    }
  }

  // Funzione utilizzata per passare i props necessari dalla pagina degli scenari alla pagina dei dispositivi
  // quando si modifica uno scenario.
  function scenariosToDevices(scenario: ScenarioInterface) {
    context.setLock(false).then(() => {
      history.replace("/devices");
      context.setLock(true);
    });
  }

  // Funzione utilizzata per passare i props necessari dalla pagina dei dispositivi alla pagina degli scenari
  // quando, in modalità modifica scenario, si salva.
  async function devicesToScenarios(scenario: ScenarioInterface) {
    context
      .setScenario(scenario)
      .then(() =>
        history.replace(
          `/scenarios/${scenario.newScenario ? "new" : scenario.id}`
        )
      );
  }

  function updateWindowDimension() {
    let docked = window.innerWidth > 768 ? true : false;
    setDocked(docked);
  }

  function setCompact(newVal: boolean) {
    localStorage.setItem("compact", newVal.toString());
    setCompactSidebar(newVal);
  }

  function setFavorite(newFavoriteLevels?: number[]) {
    if (newFavoriteLevels) {
      localStorage.setItem("favoriteLevels", JSON.stringify(newFavoriteLevels));
    } else {
      localStorage.removeItem("favoriteLevels");
    }
    setFavoriteLevels(newFavoriteLevels);
  }
};
