import { Box, Button, Slider, Typography, useTheme } from "@material-ui/core";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Device, statusEnum, Thermofan } from "../../../../Interface";

interface Props {
    device: Thermofan;
    updateDevice: (device: Device) => void;
}

const marks = [
    { value: -33, label: "Auto" },
    { value: 0, label: "Off" },
    { value: 33, label: "1" },
    { value: 66.5, label: "2" },
    { value: 100, label: "3" },
];

export const ThermofanControl: FunctionComponent<Props> = (props) => {
    const { device, updateDevice } = props;
    const theme = useTheme();
    const [setpoint, setSetpoint] = useState(device.thermofan.setpoint?.value);
    const [fanspeed, setFanspeed] = useState(device.thermofan.fanSpeed?.value);
    const deviceSetpoint = device.thermofan.setpoint?.value
    useEffect(() => {
        setSetpoint(device.thermofan.setpoint?.value);
    }, [device.thermofan.setpoint, deviceSetpoint]);

    const fanspeedChange = (
        _ev: React.ChangeEvent<{}>,
        val: number | number[]
    ) => {
        let newValue: number = Number(val);
        const fanSpeed = device.thermofan.fanSpeed;
        if (fanSpeed !== undefined) {
            updateDevice({
                ...device,
                thermofan: {
                    ...device.thermofan,
                    fanSpeed: {
                        min: device.thermofan.fanSpeed?.min || 0,
                        max: device.thermofan.fanSpeed?.max || 100,
                        value: newValue,
                    },
                },
            });
        }
    };
    return (
        <Box display="flex" justifyContent="center">
            <Box
                minWidth="125px"
                borderRadius="10px"
                padding="10px"
                display="flex"
                flexDirection="column"
                bgcolor={theme.palette.background.default}
            >
                <Button
                    style={{ flexGrow: 1, minHeight: "50px", marginBottom: "10px" }}
                    variant={
                        device.thermofan.status === statusEnum.ON ? "contained" : undefined
                    }
                    onClick={() =>
                        updateDevice({
                            ...device,
                            thermofan: {
                                ...device.thermofan,
                                status: statusEnum.ON,
                            },
                        })
                    }
                    color="primary"
                >
                    ON
        </Button>
                <Button
                    style={{ flexGrow: 1, minHeight: "50px" }}
                    color="primary"
                    variant={
                        device.thermofan.status === statusEnum.OFF ? "contained" : undefined
                    }
                    onClick={() =>
                        updateDevice({
                            ...device,
                            thermofan: {
                                ...device.thermofan,
                                status: statusEnum.OFF,
                            },
                        })
                    }
                >
                    OFF
        </Button>
            </Box>
            <Box flexGrow="1" display="flex" flexDirection="column" paddingLeft={3}>
                <Box
                    textAlign="right"
                    display="flex"
                    justifyContent="flex-start"
                    flexDirection="row-reverse"
                >
                    <Box>
                        {device.thermofan.setpoint !== undefined && (
                            <Typography variant="h4" noWrap>
                                {Number(device.thermofan.setpoint.value).toFixed(1)} ˚C
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Typography variant="h6" align="right">
                    {statusEnum[device.thermofan.status]}
                </Typography>
                <Box padding={2}>
                    <Typography>Setpoint:</Typography>
                    <Slider
                        value={setpoint}
                        valueLabelDisplay="auto"
                        marks={true}
                        onChange={(_, value) => setSetpoint(value as number)}
                        onChangeCommitted={(_, value) => {
                            updateDevice({
                                ...device,
                                thermofan: {
                                    ...device.thermofan,
                                    setpoint: device.thermofan.setpoint
                                        ? {
                                            ...device.thermofan.setpoint,
                                            value: value as number,
                                        }
                                        : undefined,
                                },
                            });
                        }}
                        min={device.thermofan.setpoint?.min}
                        max={device.thermofan.setpoint?.max}
                    />
                </Box>
                <Box padding={2}>
                    <Typography>Fanspeed:</Typography>
                    <Slider
                        value={fanspeed}
                        valueLabelDisplay="auto"
                        marks={marks}
                        min={-33}
                        step={null}
                        onChange={(_, value) => setFanspeed(value as number)}
                        onChangeCommitted={fanspeedChange}
                        max={device.thermofan.fanSpeed?.max}
                    />
                </Box>
            </Box>
        </Box>
    );
};
